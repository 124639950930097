<template>
  <div style=" padding:15px">
    <v-data-table
    :headers="[{ text: 'Fraccion', value: 'nombre' }, { text: '', value: 'actions', width: '60px' }]"
    :items="fracciones" item-key="nombre" class="elevation-1" hide-default-footer disable-pagination>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>ARTÍCULO 71 OBLIGACIONES ESPECÍFICAS. Ley General de Transparencia y Acceso a la Información Pública</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn color="primary" @click="verDocs(item)"><i class="fa fa-file"></i>&nbsp;Ver Archivos</v-btn>
      </template>
    </v-data-table>
    <v-dialog width="500" v-model="docsModal" persistent>
			<v-card style="margin:0" v-if="fraccionSelect">
				<v-toolbar dark  elevation="0" class="cabecera-azul">
					<v-btn text v-on:click="cerrar"><i class="fa fa-times"></i></v-btn><v-spacer></v-spacer>{{ fraccionSelect.nombre }}
				</v-toolbar>
				<v-container>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Archivo
                  </th>
                  <th class="text-left">
                    Tipo
                  </th>
                  <th class="text-left">
                    link
                  </th>
                  <th class="text-left">
                    ver
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="a, index in fraccionSelect.archivos" :key="a.id + 'arc'">
                  <td>{{ a.nombre }}</td>
                  <td>{{ a.archivo.tipo }}</td>
                  <td><v-btn x-small outlined id="chido" v-on:click="copy('chido', a)"><i class="fa fa-link"></i></v-btn></td>
                  <td><v-btn x-small outlined v-on:click="ver(a)"><i class="fa fa-file"></i></v-btn></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
					<v-card-actions>
						<v-btn color="red" dark v-on:click="cerrar">Cancelar</v-btn>
					</v-card-actions>
				</v-container>
			</v-card>
		</v-dialog>
  </div>
</template>

<script>
import Fraccion from '../../models/Fraccion';
import Helper from '../../utils/Helper';
export default {
  components:{},
  props: {
		articulo: {
			type:Number,
			default() {return 1;}
		},
		anho: {
			type:Number,
			default() {return 1;}
		},
	},
  data () { return { 
    fraccionSelect: null,
    docsModal: false,
		fracciones: [],
    expanded: [],
    singleExpand: false,
    dialog: false,
    toolsIndex: null
	}},
	mounted() {
		this.cargarFracciones();
	},
	methods: {
    expandRow(item) {
      console.log('row expanded: ' + item);
      this.expanded = item === this.expanded[0] ? [] : [item];
    },
		copy(id, a) {
			Helper.copyClipboard(document.getElementById(id), a.archivo.url).then(() => {  alert("Link copiado"); })
		},
		ver(a) {
			console.log(a.archivo.tipo);
			let tipos = ['xlsx','xls','xlsm'];
			if(tipos.includes(a.archivo.tipo)) {
				window.open('https://view.officeapps.live.com/op/view.aspx?src='+a.archivo.url , '_blank');
			} else {
				window.open(a.archivo.url , '_blank');
			}
		},
		cargarFracciones() {
			let f = new Fraccion();
			this.$store.commit('setsplash',{activar:true,texto:'Cargando datos...'});
			f.listarFracciones(this.articulo, this.anho).then(response => {
				this.fracciones = response.data;
				this.$store.commit('setsplash',{activar:false,texto:''});
			});
		},
    verDocs (fraccion) {
      this.fraccionSelect = fraccion
      this.docsModal = true
    },
    cerrar () {
      this.fraccionSelect = null
      this.docsModal = false
    }
	},
	computed: {},
	watch: {
		articulo() {
			this.cargarFracciones();
		},
		anho() {
			this.cargarFracciones();
		},
	},
}
</script>

<style>
</style>
