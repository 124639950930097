<template>
	<v-app> 
		<NavBar />
		<v-main>
      <v-container fluid>
        <h2><i class="fa fa-newspaper"></i> Transparencia</h2>
        <v-row>
          <v-col cols=3></v-col>
          <v-col v-for="a in articulos" :key="a.articulo" class="text-center">
            <v-btn color="#AC0101" outlined elevation=0 dark v-on:click="cargarArticulo(a)">
              <div>{{a.nombre}}</div>
            </v-btn>
          </v-col>
          <v-col cols=3></v-col>
        </v-row>
      </v-container>
			<div v-if="!!articulo">
				<v-tabs v-model="tab2">
					<v-tab v-for="anho in anhos" :key="anho">{{anho}}</v-tab>
				</v-tabs>
				<v-tabs-items v-model="tab2">
					<v-tab-item v-for="anho in anhos" :key="anho + 'aa'">
						<FraccionesList :anho="anho" :articulo="articulo.articulo" :key="anho + 'bb'" />
					</v-tab-item>
				</v-tabs-items>
			</div>
		</v-main>
		<Footer />
	</v-app>
</template>

<script>
import NavBar from '../generales/NavBar';
import Fraccion from '../models/Fraccion';
import Footer from '../generales/Footer';
import FraccionesList from '../reutilizables/fracciones/FraccionesList';
export default {
  components:{NavBar, FraccionesList, Footer},
  data () { return { 
		tab2:0,
		arti:true,
		articulos:[
			{articulo:70, nombre:'Articulo 70', text:'ARTÍCULO 70 OBLIGACIONES COMUNES. Ley General de Transparencia y Acceso a la Información Pública'},
			{articulo:71, nombre:'Articulo 71', text:'ARTÍCULO 71 OBLIGACIONES ESPECÍFICAS. Ley General de Transparencia y Acceso a la Información Pública'},
			{articulo:30, nombre:'Articulo 30', text:'Además de lo señalado en el artículo 71 de la Ley General, y en el artículo 19 del presente ordenamiento, los Municipios deberán poner a disposición del público y mantener actualizada la siguiente información:'},
			{articulo:36, nombre:'Articulo 36', text:'Los sujetos obligados que realicen obra pública, deberán difundir físicamente en el lugar de la obra, una placa o inscripción que señale que fue realizada con recursos públicos y el costo de la misma.'}
		],
		articulo: null,
		anho:null,
		anhos:[],
	}},
	mounted() {},
	methods: {
		cargarArticulo(a) {
			this.articulo = a;
		},
		cargarAnhos() {
			let f = new Fraccion();
			this.$store.commit('setsplash',{activar:true,texto:'Cargando datos...'});
			f.anhosFraccion(this.articulo.articulo).then(response => {
				this.anhos = response.data;
				this.$store.commit('setsplash',{activar:false,texto:''});
			});
		},
	},
	computed: {},
	watch: {
		articulo(value) {
			this.cargarAnhos();
		},
		tab2() {
			this.anho = this.anhos[this.tab2];
		}
	},
}
</script>

<style>
</style>
