<template>
	<v-app style="" v-if="!!obra"> 
		<NavBar />
		<v-card-title>{{obra.nombre}}</v-card-title>
		<v-main class="principal">
			<v-row class="fila">
				<v-col class="columna" cols=6>
					<v-card-title>Informacion General</v-card-title>
					<v-simple-table>
						<template v-slot:default>
							<tbody>
								<tr>
									<td>Nombre</td>
									<td>{{ obra.nombre }}</td>
								</tr>
								<tr>
									<td>Localidad</td>
									<td>{{ obra.localidad }}</td>
								</tr>
								<tr>
									<td>Numero de Contrato</td>
									<td>{{ obra.num_contrato }}</td>
								</tr>
								<tr>
									<td>Sector</td>
									<td>{{ obra.sector }}</td>
								</tr>
								<tr>
									<td>Año</td>
									<td>{{ obra.anho }}</td>
								</tr>
								<tr>
									<td>Beneficiarios</td>
									<td>{{ obra.beneficiarios }}</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</v-col>
				<v-col class="columna" cols=6>
					<v-card-title>Archivos de la Obra</v-card-title>
					<v-simple-table>
						<template v-slot:default>
							<tbody>
								<tr v-if="!!obra.contrato_id">
									<td>{{contratoText}}</td>
									<td style="width:70px">
										<v-card elevation=0 class="text-center" style="background-color: transparent!important;"
										v-on:click="ver(obra.contrato)" :key="'a1'">
											<div><i class="fa fa-file" ></i></div>
											<div class="text-tini">Ver</div>
										</v-card>
									</td>
									<td style="width:70px">
										<v-card elevation=0 class="text-center" style="background-color: transparent!important;"
										id="chido" v-on:click="copy('chido', obra.contrato)" :key="'b1'">
											<div><i class="fa fa-link" ></i></div>
											<div class="text-tini">Link</div>
										</v-card>
									</td>
								</tr>
								<tr v-for="a,index in archivosDeObra">
									<td>{{a.nombre}}</td>
									<td style="width:70px">
										<v-card elevation=0 class="text-center" style="background-color: transparent!important;"
										v-on:click="ver(a.archivo)" :key="index+'ba'">
											<div><i class="fa fa-file" ></i></div>
											<div class="text-tini">Ver</div>
										</v-card>
									</td>
									<td style="width:70px">
										<v-card elevation=0 class="text-center" style="background-color: transparent!important;"
										id="chido" v-on:click="copy('chido', a.archivo)" :key="index+'b1'">
											<div><i class="fa fa-link" ></i></div>
											<div class="text-tini">Link</div>
										</v-card>
									</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</v-col>
			</v-row>
			<div v-if="obra.imagenes.length > 0" >
				<v-card-title>Imagenes</v-card-title>
				<Galeria :imagenes="obra.imagenes" :acciones="false"/>
			</div>
		</v-main>
		<Footer />
		<v-snackbar v-model="snackbar" :vertical="true" :timeout="2000" >
			Link Copiado 
 			<template v-slot:action="{ attrs }">
				<v-btn color="indigo" text v-bind="attrs"
				@click="snackbar = false" >
				Cerrar
				</v-btn>
			</template>
		</v-snackbar>
	</v-app>
</template>

<script>
import NavBar from '../generales/NavBar';
import Footer from '../generales/Footer';
import Galeria from '../generales/Galeria';
import Obra from '../models/Obra'
import Helper from '../utils/Helper';
export default {
  components:{NavBar,Footer, Galeria},
  data () { return { 
		obra:null,
		snackbar:false,
	}},
	mounted() {
		let obra_id = this.$route.params.id;
		let o = new Obra();
		this.$store.commit('setsplash',{activar:true,texto:'Cargando informacion...'});
		o.ver(obra_id).then(response => {
			this.obra = response.data;
			this.$store.commit('setsplash',{activar:false,texto:''});
		});
	},
	methods: {
		ver(archivo) {
			window.open(archivo.url, '_blank');
		},
    copy(id, archivo) {
			Helper.copyClipboard(document.getElementById(id), archivo.url).then(() => {  alert("Link copiado"); })
		}
	},
	computed: {
		contratoText() {
			return this.obra.admin_directa?'Acta de adjudicacion directa':'Contrato';
		},
		archivosDeObra() {
			let docs = [
				{
					titulo:'Acta Entrega Recepcion del Contratista al Municipio',
					field:'acta_contratista',
				},
				{
					titulo:'Acta Entrega Recepcion del Municipio al Comite de Obras',
					field:'acta_comite',
				},
				{
					titulo:'Fianza de Cumplimiento',
					field:'fianza_cumplimiento',
				},
				{
					titulo:'Fianza de Vicios Ocultos',
					field:'fianza_vicios',
				},
				{
					titulo:'Fianza Anticipo',
					field:'fianza_anticipo',
				},
			];
			let archivos = [];
			docs.forEach((d) => {
				if(!!this.obra[d.field+'_id']) {
					archivos.push({
						archivo:this.obra[d.field],
						nombre:d.titulo,
					});
				}
			});
			return archivos;
		}
	},
	watch: {},
}
</script>

<style>
</style>
