import Model from "./Model.js";

export default class Obra extends Model {
	
	constructor() {
		super("obras");
	}
	
	async anhosObras() {
		return await this.post("anhos_obras");
	}
	
	async ultimas() {
		return await this.get("ultimas");
	}
	
}
