<template>
<div id="app">
  <v-app>
    <NavBar />
    <v-main>
      <section id="hero">
        <v-row no-gutters>
          <v-carousel :min-height="'calc(100vh - ' + $vuetify.application.top + 'px)'" > 
						<v-carousel-item v-for="(item,i) in items" :key="i" :src="item.src" reverse-transition="fade-transition" 
						transition="fade-transition"></v-carousel-item>
					</v-carousel>
        </v-row>
      </section>
      <section id="blog">
        <div class="py-12"></div>

        <v-container>
          <h2 class="display-2 font-weight-bold mb-3 text-uppercase text-center">Convocatorias y Eventos</h2>

          <v-responsive
            class="mx-auto mb-12"
            width="56"
          >
            <v-divider class="mb-1"></v-divider>

            <v-divider></v-divider>
          </v-responsive>

          <v-row>
            <v-col
              v-for="(n, index) in noticias" :key="index"
              cols="12"
              md="4"
            >
              <v-img
                :src="n.portada"
                class="mb-4"
                height="275"
                max-width="100%"
              ></v-img>

              <h3 class="font-weight-black mb-4 text-uppercase">{{ n.titulo }}</h3>

              <div class="title font-weight-light mb-5">{{ n.texto }}</div>

              <v-btn
                class="ml-n4 font-weight-black"
                text
              >
                Continue Reading
              </v-btn>
            </v-col>
          </v-row>
        </v-container>

        <div class="py-12"></div>
      </section>
      
      <section id="stats">
        <v-parallax
          :height="$vuetify.breakpoint.smAndDown ? 700 : 500"
          :src="paralax1"
        >
        </v-parallax>
      </section>
      
      <section id="about-me">
        <div class="py-12"></div>

        <v-container class="text-center">
          <h2 class="display-2 font-weight-bold mb-3">ACATLAN DE PEREZ FIGUEROA</h2>

          <v-responsive class="mx-auto mb-8" width="56">
            <v-divider class="mb-1"></v-divider>
            <v-divider></v-divider>
          </v-responsive>

          <v-responsive class="mx-auto title font-weight-light mb-8" max-width="720">
            Cabildo que integra el H. Ayuntamiento de Acatlan de Perez Figueroa
          </v-responsive>
          <v-row>
            <v-col v-for="c,index in contactos" :key="index">
              <div>
                <v-avatar class="elevation-12 mb-12" size="128">
                  <v-img :src="c.url"></v-img>
                </v-avatar>
              </div>
              <div>
                <span class="grey--text text--darken-1 font-weight-bold">
                  {{ c.puesto }}
                </span>
              </div>
            </v-col>
          </v-row>
          <div></div>
        </v-container>

        <div class="py-12"></div>
      </section>

      <section
        id="features"
        class="grey lighten-3"
      >
        <div class="py-12"></div>

        <v-container class="text-center">
          <h2 class="display-2 font-weight-bold mb-3">OBRAS PUBLICAS</h2>

          <v-responsive
            class="mx-auto mb-12"
            width="56"
          >
            <v-divider class="mb-1"></v-divider>

            <v-divider></v-divider>
          </v-responsive>

          <v-row>
            <v-col
              v-for="(o, i) in obras"
              :key="i"
              cols="12"
              md="4"
            >
              <v-card
                class="py-12 px-4"
                color="grey lighten-5"
                flat
              >
                <v-theme-provider dark>
                  <div>
                    <v-avatar
                      color="primary"
                      size="88"
                    >
                      <v-img :src="portadaObra(o)"></v-img>
                    </v-avatar>
                  </div>
                </v-theme-provider>

                <v-card-title class="justify-center font-weight-black text-uppercase" >{{ o.nombre }}</v-card-title>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>

        <div class="py-12"></div>
      </section>

      <section id="stats">
        <v-parallax
          :height="$vuetify.breakpoint.smAndDown ? 700 : 500"
          :src="paralax2"
        >
          <v-container fill-height>
            <v-row class="mx-auto">
              <v-col cols="12"
                md="3">
                <div class="text-center">
                  <div class="display-3 font-weight-black mb-4">{{ municipio }}</div>

                  <div class="title font-weight-regular text-uppercase">{{ slogan }}</div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-parallax>
      </section>
      <Contacto />
    </v-main>
    
    <Footer />
  </v-app>
 </div>
</template>

<script>
import NavBar from '../generales/NavBar'
import Footer from '../generales/Footer'
import Contacto from '../generales/Contacto'
import Obra from '../models/Obra'
export default {
  components:{ NavBar, Footer, Contacto },
  data () { return { 
    obras: [],
		noticias: [
			{
				titulo:"Salud ",
				texto:"La salud es primero y eso lo sabe el presidente municipal de Acatlán de Pérez Figueroa, Jesús Ortega Marín, quien tuvo....",
				id:1,
				portada:"https://scontent.foax1-1.fna.fbcdn.net/v/t39.30808-6/279453493_2128766407300418_8170949480636799211_n.jpg?_nc_cat=100&ccb=1-5&_nc_sid=8bfeb9&_nc_eui2=AeGhyINvL6HlTxr5YUyQ2CcrcchedamMH9RxyF51qYwf1NtyAhTfnSg14-66Kqf10ooHIm_wCts8BzJcoHTw5FXR&_nc_ohc=OTeL6LskbgUAX-d-YZ_&_nc_ht=scontent.foax1-1.fna&oh=00_AT8rOvCR0RmWDX2WBbAyQ7uDvMK-7bJtRD7Ish_JPfGAOg&oe=6271464A",
			}, 
			{
				titulo:"Juventud ",
				texto:"El Instituto Acateco de la Juventud, H. Ayuntamiento de Acatlán y Word Vision invita a participar a jóvenes becarios...",
				id:2,
				portada:"https://scontent.foax1-1.fna.fbcdn.net/v/t39.30808-6/279505850_2128582310652161_7010822749988654016_n.jpg?_nc_cat=102&ccb=1-5&_nc_sid=8bfeb9&_nc_eui2=AeEKE90K9vUDELaLGJRRAh-Rwdje6Itd-hjB2N7oi136GE6NnyMkZtAnCi19dnhqAc1YJITtWy5fpdGQ0pJ3FOvV&_nc_ohc=R14IzmtitsoAX9JpTBA&_nc_ht=scontent.foax1-1.fna&oh=00_AT_y1B-UO2dlS4GiDyPGZoMPElZxYi4C2OF3TeVlaAiYxw&oe=62713405",
			},
			{
				titulo:"Salud ",
				texto:"La salud es primordial para el regreso a clase  por ello la regidora de educación la maestra  María de Lourdes López...",
				id:3,
				portada:"https://scontent.foax1-1.fna.fbcdn.net/v/t39.30808-6/279435639_2127932100717182_669348911966349040_n.jpg?_nc_cat=111&ccb=1-5&_nc_sid=8bfeb9&_nc_eui2=AeGlfayOVL4Hi2E364st4BDASu9I-kVaVkZK70j6RVpWRpM-phfQ9WzlYCek_8AkJRx9JclfnJbJXIESbSZFIl4b&_nc_ohc=87-nkqlti-sAX_CtOtc&_nc_ht=scontent.foax1-1.fna&oh=00_AT-QOa9w2z5TSdXdLnyvmAz3cusBW95diSTsvm4Qi98WUg&oe=6270CF7D",
			}
			
		],
    features: [
      {
        icon: 'mdi-account-group-outline',
        title: '',
        text: 'CONSTRUCCION DE TECHADO EN AREA  DEPORTIVA NUM 56 EN LA LOCALIDAD DE VILLALPANDO ZARAGOZA',
      },
      {
        icon: 'mdi-update',
        title: 'Frequent Updates',
        text: 'Sed ut elementum justo. Suspendisse non justo enim. Vestibulum cursus mauris dui, a luctus ex blandit. Lorem ipsum dolor sit amet consectetur adipisicing elit. qui ipsum eveniet facilis obcaecati corrupti consectetur adipisicing elit.',
      },
      {
        icon: 'mdi-shield-outline',
        title: 'Long-term Support',
        text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto cupiditate sint possimus quidem atque harum excepturi nemo velit tempora! Enim inventore fuga, qui ipsum eveniet facilis obcaecati corrupti asperiores nam',
      },
    ],
    stats: [
      ['', 'COMPROMISO'],
      ['', 'LEALTAD'],
      ['', 'HONRADEZ'],
      ['', 'SEGURIDAD'],
    ],
    items: [
      {
				src:"https://scontent.foax1-1.fna.fbcdn.net/v/t39.30808-6/279453493_2128766407300418_8170949480636799211_n.jpg?_nc_cat=100&ccb=1-5&_nc_sid=8bfeb9&_nc_eui2=AeGhyINvL6HlTxr5YUyQ2CcrcchedamMH9RxyF51qYwf1NtyAhTfnSg14-66Kqf10ooHIm_wCts8BzJcoHTw5FXR&_nc_ohc=OTeL6LskbgUAX-d-YZ_&_nc_ht=scontent.foax1-1.fna&oh=00_AT8rOvCR0RmWDX2WBbAyQ7uDvMK-7bJtRD7Ish_JPfGAOg&oe=6271464A",
			}, 
			{
				src:"https://scontent.foax1-1.fna.fbcdn.net/v/t39.30808-6/279505850_2128582310652161_7010822749988654016_n.jpg?_nc_cat=102&ccb=1-5&_nc_sid=8bfeb9&_nc_eui2=AeEKE90K9vUDELaLGJRRAh-Rwdje6Itd-hjB2N7oi136GE6NnyMkZtAnCi19dnhqAc1YJITtWy5fpdGQ0pJ3FOvV&_nc_ohc=R14IzmtitsoAX9JpTBA&_nc_ht=scontent.foax1-1.fna&oh=00_AT_y1B-UO2dlS4GiDyPGZoMPElZxYi4C2OF3TeVlaAiYxw&oe=62713405",
			},
			{
				src:"https://scontent.foax1-1.fna.fbcdn.net/v/t39.30808-6/279435639_2127932100717182_669348911966349040_n.jpg?_nc_cat=111&ccb=1-5&_nc_sid=8bfeb9&_nc_eui2=AeGlfayOVL4Hi2E364st4BDASu9I-kVaVkZK70j6RVpWRpM-phfQ9WzlYCek_8AkJRx9JclfnJbJXIESbSZFIl4b&_nc_ohc=87-nkqlti-sAX_CtOtc&_nc_ht=scontent.foax1-1.fna&oh=00_AT-QOa9w2z5TSdXdLnyvmAz3cusBW95diSTsvm4Qi98WUg&oe=6270CF7D",
			}
		],
    contactos: [
      { puesto: 'Presidencia Municipal', url: '' },
      { puesto: 'Tesoreria', url: '' },
    ]
	}},
	mounted () {
    this.cargarDatos().then(() => {})
  },
	methods: {
    async cargarDatos () {
      let o = new Obra()
      this.obras = (await o.ultimas()).data
      console.log('obras', this.obras)
    },
    portadaObra (o) {
      return o.imagenes.length > 0? o.imagenes[0].archivo.url:''
    }
  },
	computed: {
    paralax1 () {
      return !this.$store.state.config.Paralax1? 'http://api-pagobtra.test/acatlan2.jpg': this.$store.state.config.Paralax1
    },
    paralax2 () {
      return !this.$store.state.config.Paralax2? 'http://api-pagobtra.test/acatlan2.jpg': this.$store.state.config.Paralax2
    },
    slogan () {
      return !this.$store.state.config.Slogan? '': this.$store.state.config.Slogan
    },
    municipio () {
      return !this.$store.state.config['Nombre Municipio']? '': this.$store.state.config['Nombre Municipio']
    }
  },
	watch: {},
}
</script>

<style>
</style>
