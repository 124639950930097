<template>
	<v-card>
		<v-app-bar dark color="pink" tile>
			<v-card-title>Noticias</v-card-title> 
			<v-spacer></v-spacer>
			<v-btn color="indigo">Crear Noticia</v-btn>
		</v-app-bar>
		<v-card-text>
			<v-data-table :headers="cabecera" :items="items"  class="elevation-1" >
				<template v-slot:item.acciones="{ item }">
					<v-btn text><i class="fa fa-edit"></i></v-btn>
					<v-btn text><i class="fa fa-trash"></i></v-btn>
				</template>
			</v-data-table>
		</v-card-text>
	</v-card>
</template>

<script>
export default {
  components:{},
  data () { return { 
		cabecera:[
			{ text: 'Titulo', value: 'titulo' },
      { text: 'Fecha', value: 'created_at' },
      { text: 'Tipo', value: 'tipo' },
      { text: 'Acciones', value: 'acciones', sortable: false },
		],
		items:[
			{
				titulo: 'Convocatoria Evento deportivo',
				created_at: '2021-10-10',
				tipo: "Convocatoria",
			},
			{
				titulo: 'Tequio comunitario',
				created_at: '2021-10-10',
				tipo: "Noticia",
			},
			{
				titulo: 'Fiesta patronal',
				created_at: '2021-10-10',
				tipo: "Noticia",
			},
		],
	}},
	mounted(){},
	methods: {},
	computed: {},
	watch: {},
}
</script>

<style>
</style>
