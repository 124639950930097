<template>
  <v-app-bar
    app
    color="white"
    height="100"
    prominent
  >
    <v-row>
      <v-col cols="3">{{ municipio }}</v-col>
      <v-col cols="1"></v-col>
      <v-col cols="1"><v-btn elevation=0 text block v-on:click="ir(1)"><i class="fa fa-home"></i> Inicio</v-btn></v-col>
      <v-col cols="2"><v-btn elevation=0 text block v-on:click="ir(2)"><i class="fa fa-book-open"></i> Transparencia</v-btn></v-col>
      <v-col cols="1"><v-btn elevation=0 text block v-on:click="ir(3)"><i class="fa fa-hard-hat"></i> Obras</v-btn></v-col>
      <v-col cols="1"><v-btn elevation=0 text block v-on:click="ir(4)"><i class="fa fa-rss-square"></i> Gaceta</v-btn></v-col>
      <v-col cols="2"><v-btn elevation=0 text block v-on:click="ir(5)"><i class="fa fa-landmark"></i> Contraloria Social</v-btn></v-col>
      <v-col cols="1"><v-btn elevation=0 text block v-on:click="ir(6)"><i class="fa fa-archive"></i> CONAC</v-btn></v-col>
    </v-row>
  </v-app-bar>
	
</template>

<script>
export default {
  components:{},
  data () { return { }},
	mounted(){},
	methods: {
		ir(view) {
			switch(view) {
				case 1: this.$router.push('/'); break;
				case 2: this.$router.push('/Transparencia'); break;
				case 3: this.$router.push('/Obras'); break;
				case 4: this.$router.push('/Gaceta'); break;
				case 5: this.$router.push('/Contraloria_Social'); break;
				case 6: this.$router.push('/CONAC'); break;
			}
		}
	},
	computed: {
    municipio () {
      return !this.$store.state.config['Nombre Municipio']? '': this.$store.state.config['Nombre Municipio']
    }
  },
	watch: {},
}
</script>

<style>
</style>
