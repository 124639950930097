import Model from "./Model.js";

export default class Fraccion extends Model {
	
	constructor() {
		super("fracciones");
	}
	
	async listarFracciones(articulo, anho) {
		return await this.post("listar_fracciones", {articulo, anho});
	}
	
	async anhosFraccion(articulo) {
		return await this.post("anhos_fracciones", articulo);
	}
	
	async agregarArchivo(data) {
		return await this.post("agregar_archivo",data);
	}
	
	async borrarArchivo(fraccion_archivo_id) {
		return await this.post("borrar_archivo",{fraccion_archivo_id});
	}
	
	async crearBloque(articulo, anho) {
		return await this.post("crear_bloque", {articulo, anho});
	}
	
}
