import Api from '../utils/Api';
export default class Model {
	
	constructor(tabla) {
		this.tabla = tabla;
	}
	
	/*=========metodos para consumir api==========*/
	
	async post (action, data) {
		let url = "/"+this.tabla+"/"+action;
		console.log("posteando a " + url);
		let response = await Api.post(url, data);
		return response;
	}
	
	async get (action) {
		let url = "/"+this.tabla+"/"+action;
		console.log("get a " + url);
		let response = await Api.get(url);
		return response;
	}
	
	
	async guardar(data) {
		return await this.post("guardar", data);
	}
	
	async agregar(data) {
		return await this.post("agregar", data);
	}
	
	
	async actualizar(data) {
		return await this.post("actualizar", data);
	}
	
	
	async ver(id) {
		return await this.post("ver", id);
	}
	
	async listar(data) {
		return await this.post("listar", data);
	}
	
	async buscarPor(campo,valor) {
		return await this.post("buscarpor", {campo,valor});
	}
	
	async borrar(Id) {
		return await this.post("borrar", {id:Id});
	}
}
