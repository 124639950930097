<template>
	<v-footer
    class="justify-center"
    color="#292929"
    height="100"
  >
    <div class="title font-weight-light grey--text text--lighten-1 text-center">
      &copy; {{ (new Date()).getFullYear() }} — Made with 💜 by TICS Oaxaca - <router-link :to="'/login'" target="_blank">Iniciar Sesion</router-link> 
    </div>
  </v-footer>
</template>

<script>
export default {
  components:{},
  data () { return {  }},
	mounted(){},
	methods: {},
	computed: {},
	watch: {},
}
</script>

<style>
</style>
