import Model from "./Model.js";

export default class Archivo extends Model {
	
	constructor() {
		super("archivos");
	}
	
	async subirArchivo(data) {
		return await this.post("subirarchivo", data);
	}
	
	async agregarArchivoObra(data) {
		return await this.post("agregar_archivo_obra", data);
	}
	
	async borrarArchivoObra(campo,obra_id) {
		return await this.post("borrar_archivo_obra", {campo,obra_id});
	}
	
	async agregarImagenObra(data) {
		return await this.post("agregar_imagen_obra", data);
	}
	
	async borrarImagenObra(obra_id,imagen_obra_id) {
		return await this.post("borrar_imagen_obra", {obra_id, imagen_obra_id});
	}
	
	async listarImagenes() {
		return await this.get("imagenes");
	}
}
