<template>
	<v-card>
		<v-app-bar dark color="pink" tile><v-card-title>CONAC</v-card-title></v-app-bar>
		<v-row class="fila">
			<v-col class="columna" cols="2"><v-btn  color="blue" dark v-on:click="bloqueModal = true">Agregar Bloque de Año</v-btn></v-col>
		</v-row>
		<v-tabs v-model="tab2">
			<v-tab v-for="anho in anhos" :key="anho">{{anho}}</v-tab>
		</v-tabs>
		<v-tabs-items v-model="tab2">
			<v-tab-item v-for="anho in anhos" :key="anho + 'aa'">
				<NormasFormList :anho="anho" :key="anho + 'bb'"/>
			</v-tab-item>
		</v-tabs-items>
		
		<!-- -->
		<v-dialog width="300" v-model="bloqueModal" persistent>
			<v-card style="margin:0">
				<v-toolbar dark  elevation="0" class="cabecera-azul">
					<v-btn text v-on:click="cerrarForm"><i class="fa fa-times"></i></v-btn>
				</v-toolbar>
				<v-container>
					<v-row>
						<v-col>Año a Agregar</v-col>
						<v-col> <v-text-field v-model="anhoBloque" type="number"></v-text-field></v-col>
					</v-row>
					<v-card-actions>
						<v-btn color="primary" v-on:click="crearBloque">Crear</v-btn>
						<v-btn color="red" dark v-on:click="cerrarForm">Cancelar</v-btn>
					</v-card-actions>
				</v-container>
			</v-card>
		</v-dialog>
	</v-card>
</template>

<script>
import NormasFormList from '../reutilizables/normas/NormasFormList'
import Norma from '../models/Norma'
import Helper from '../utils/Helper'
export default {
  components:{NormasFormList},
  data () { return { 
		tab2:0,
		anhoBloque: null,
		anho: null,
		bloqueModal: false,
		anhos:[],
	}},
	mounted() {
		this.cargarAnhos();
	},
	methods: {
		cargarAnhos() {
			let f = new Norma();
			this.$store.commit('setsplash',{activar:true,texto:'Cargando datos...'});
			f.anhosNorma().then(response => {
				this.anhos = response.data;
				this.$store.commit('setsplash',{activar:false,texto:''});
			});
		},
		crearBloque() {
			if(!Helper.esNumero(this.anhoBloque)) {
				return;
			}
			if(this.anhos.includes(Number(this.anhoBloque))) {
				alert("ya esta registrado este año");
				return;
			}
			let f = new Norma();
			this.$store.commit('setsplash',{activar:true,texto:'Generando Informacion...'});
			f.crearBloque(this.anhoBloque).then(response => {
				this.anhos = response.data;
				this.cerrarForm();
				this.$store.commit('setsplash',{activar:false,texto:''});
			});
		},
		cerrarForm() {
			this.anhoBloque = false;
			this.bloqueModal = false;
		},
	},
	computed: {},
	watch: {
		tab2() {
			this.anho = this.anhos[this.tab2];
		}
	},
}
</script>

<style>
</style>
