<template>
	<v-card>
    <input id="buscar-imagen" style="display:none" type="file" v-on:change="subirImagen" accept="image/*"/>
    <v-app-bar dark color="pink" tile>
			<v-card-title>Configuraciones</v-card-title> 
			<v-spacer></v-spacer>
		</v-app-bar>
		<v-container>
      <v-row>
        <v-col cols="4">
          <h3>{{ campos[0].campo }}</h3>
        </v-col>
        <v-col>
          <v-text-field outlined v-model="campos[0].valor"></v-text-field>
          <div class="text-right"><v-btn v-on:click="guardar(0)">Guardar</v-btn></div>
        </v-col>
      </v-row>
      <v-divider class="my-3"></v-divider>
      <v-row>
        <v-col cols="4"><h3>{{ campos[1].campo }}</h3></v-col>
        <v-col>
          <v-text-field outlined v-model="campos[1].valor"></v-text-field>
          <div class="text-right"><v-btn v-on:click="guardar(1)">Guardar</v-btn></div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <div><v-btn small block v-on:click="buscarImagen(1)">Subir imagen nueva</v-btn></div>
          <div>
            <v-autocomplete :items="archivos" item-text="nombre_archivo" item-value="url" 
            v-model="campos[1].valor" label="Buscar Imagen"></v-autocomplete>
          </div>
        </v-col>
        <v-col>
          <v-img :src="campos[1].valor" max-width="300px"></v-img>
        </v-col>
      </v-row>
      <v-divider class="my-3"></v-divider>
      <v-row>
        <v-col cols="4"><h3>{{ campos[2].campo }}</h3></v-col>
        <v-col>
          <v-text-field outlined v-model="campos[2].valor"></v-text-field>
          <div class="text-right"><v-btn v-on:click="guardar(2)">Guardar</v-btn></div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <div><v-btn small block v-on:click="buscarImagen(2)">Subir imagen nueva</v-btn></div>
          <div>
            <v-autocomplete :items="archivos" item-text="nombre_archivo" item-value="url" 
            v-model="campos[2].valor" label="Buscar Imagen"></v-autocomplete>
          </div>
        </v-col>
        <v-col>
          <v-img :src="campos[2].valor" max-width="300px"></v-img>
        </v-col>
      </v-row>
      <v-divider class="my-3"></v-divider>
      <v-row>
        <v-col cols="4"><h3>{{ campos[3].campo }}</h3></v-col>
        <v-col>
          <v-textarea outlined v-model="campos[3].valor"></v-textarea>
          <div class="text-right"><v-btn v-on:click="guardar(3)">Guardar</v-btn></div>
        </v-col>
      </v-row>
      <v-divider class="my-3"></v-divider>
      <v-row>
        <v-col cols="4"><h3>{{ campos[4].campo }}</h3></v-col>
        <v-col>
          <v-textarea outlined v-model="campos[4].valor"></v-textarea>
          <div class="text-right"><v-btn v-on:click="guardar(4)">Guardar</v-btn></div>
        </v-col>
      </v-row>
      <v-divider class="my-3"></v-divider>
      <v-row>
        <v-col cols="4">
          <h3>{{ campos[5].campo }}</h3>
        </v-col>
        <v-col>
          <v-text-field outlined v-model="campos[5].valor"></v-text-field>
          <div class="text-right"><v-btn v-on:click="guardar(5)">Guardar</v-btn></div>
        </v-col>
      </v-row>
      <v-divider class="my-3"></v-divider>
      <v-row>
        <v-col cols="4">
          <h3>{{ campos[6].campo }}</h3>
        </v-col>
        <v-col>
          <v-text-field outlined v-model="campos[6].valor"></v-text-field>
          <div class="text-right"><v-btn v-on:click="guardar(6)">Guardar</v-btn></div>
        </v-col>
      </v-row>
      <v-divider class="my-3"></v-divider>
      <v-row>
        <v-col cols="4">
          <h3>{{ campos[7].campo }}</h3>
        </v-col>
        <v-col>
          <v-text-field outlined v-model="campos[7].valor"></v-text-field>
          <div class="text-right"><v-btn v-on:click="guardar(7)">Guardar</v-btn></div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <h3>{{ campos[8].campo }}</h3>
        </v-col>
        <v-col>
          <v-text-field outlined v-model="campos[8].valor"></v-text-field>
          <div class="text-right"><v-btn v-on:click="guardar(8)">Guardar</v-btn></div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <h3>{{ campos[9].campo }}</h3>
        </v-col>
        <v-col>
          <v-text-field outlined v-model="campos[9].valor"></v-text-field>
          <div class="text-right"><v-btn v-on:click="guardar(9)">Guardar</v-btn></div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <h3>{{ campos[10].campo }}</h3>
        </v-col>
        <v-col>
          <v-text-field outlined v-model="campos[10].valor"></v-text-field>
          <div class="text-right"><v-btn v-on:click="guardar(10)">Guardar</v-btn></div>
        </v-col>
      </v-row>
    </v-container>
		
		<!-- -->
		<v-dialog width="500" v-model="configModal" persistent>
			<v-card style="margin:0">
				<v-toolbar dark  elevation="0" class="cabecera-azul">
					<v-btn text v-on:click="cerrar"><i class="fa fa-times"></i></v-btn>
				</v-toolbar>
				<v-container v-if="!configSelect">
					<v-row>
						<v-col cols="2">Campo</v-col>
						<v-col> <v-text-field outlined v-model="campo" ></v-text-field></v-col>
					</v-row>
					<v-row>
						<v-col cols="2">Valor</v-col>
						<v-col> <v-textarea outlined v-model="valor" ></v-textarea></v-col>
					</v-row>
					<v-card-actions>
						<v-btn color="primary" v-on:click="guardar">Crear</v-btn>
						<v-btn color="red" dark v-on:click="cerrar">Cancelar</v-btn>
					</v-card-actions>
				</v-container>
				<v-container v-else>
					<v-row>
						<v-col cols="2">Campo</v-col>
						<v-col> <v-text-field outlined v-model="configSelect.campo" ></v-text-field></v-col>
					</v-row>
					<v-row>
						<v-col cols="2">Valor</v-col>
						<v-col> <v-textarea outlined v-model="configSelect.valor" ></v-textarea></v-col>
					</v-row>
					<v-card-actions>
						<v-btn color="primary" v-on:click="guardar">Guardar</v-btn>
						<v-btn color="red" dark v-on:click="cerrar">Cancelar</v-btn>
					</v-card-actions>
				</v-container>
			</v-card>
		</v-dialog>
	</v-card>
</template>

<script>
import Config from '../models/Config'
import Archivo from '../models/Archivo'
import Helper from '../utils/Helper'
export default {
  components: {},
  data () { return { 
    campoIndex: null,
		config: [],
		archivos: [],
    configModal: false,
    campo: null,
    valor: null,
    configSelect: null,
    headers: [
      { text: 'Nombre', value: 'campo', width: '170px' },
      { text: 'Valor', value: 'valor' },
      { text: 'Acciones', value: 'acciones', width: '100px' }
    ],
    campos: [
      { id: 1, campo: 'Nombre Municipio', valor: null },
      { id: 2, campo: 'Paralax1', valor: null },
      { id: 3, campo: 'Paralax2', valor: null },
      { id: 4, campo: 'Maps', valor: null },
      { id: 5, campo: 'Facebook View', valor: null },
      { id: 6, campo: 'Direccion', valor: null },
      { id: 7, campo: 'Telefono', valor: null },
      { id: 8, campo: 'Email', valor: null },
      { id: 9, campo: 'Horario', valor: null },
      { id: 10, campo: 'Slogan', valor: null },
      { id: 11, campo: 'Sitio Anterior', valor: null },
    ]
	}},
	mounted () {
    this.$store.commit('setsplash',{activar:true,texto:'Cargando datos...'});
		this.cargarDatos().then(() => {
      this.$store.commit('setsplash',{activar:false,texto:''});
    });
	},
	methods: {
		async cargarDatos () {
			let f = new Config();
			const response = await f.listar()
      response.data.forEach((conf) => {
        this.campos.forEach((cam, index, array) => {
          if (cam.id === conf.id) {
            this.campos[index].valor = conf.valor
          }
        })
      })
      let a = new Archivo()
      this.archivos = (await a.listarImagenes()).data
		},
    guardar (index) {
      let f = new Config();
			this.$store.commit('setsplash',{activar:true,texto:'Cargando datos...'});
      f.guardar(this.campos[index]).then(response => {
        this.config.push(response.data)
        this.cerrar()
        this.$store.commit('setsplash',{activar:false,texto:''});
      });
    },
    nuevo () {
      this.configSelect = null;
			this.campo = null;
			this.valor = null;
      this.configModal = true;
    },
    editar (item) {
      this.configSelect = item;
			this.campo = null;
			this.valor = null;
      this.configModal = true;
    },
		cerrar () {
			this.configSelect = null;
			this.campo = null;
			this.valor = null;
      this.configModal = false;
		},
    subirImagen(e) {
			let file = event.target.files[0];
			let formData = new FormData();
			console.log(file);
			formData.append('imagen', file);
			this.$store.commit('setsplash', { activar: true, texto: 'Cargando datos...' });
      let a = new Archivo();
			a.agregar(formData).then(response => {
				this.archivos.push(response.data);
        this.campos[this.campoIndex].valor = response.data.url
        this.campoIndex = null
				this.$store.commit('setsplash', { activar: false, texto:'' });
			});
		},
		buscarImagen(index) {
      this.campoIndex = index
			document.getElementById('buscar-imagen').click();
		}
	},
	computed: {},
	watch: {},
}
</script>

<style>
</style>
