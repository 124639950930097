<template>
	<v-card >
		<v-toolbar dark  elevation="0" class="cabecera-azul">
			<v-btn text v-on:click="cerrar"><i class="fa fa-times"></i></v-btn> <v-spacer></v-spacer>Obra
		</v-toolbar>
		<v-row class="fila">
			<v-col class="columna" cols=6>
				<v-card-title>Datos Generales</v-card-title>
				<v-form ref="form" v-model="valid" lazy-validation >
					<v-card style="margin:0; padding:20px;" elevation="0">
						<v-text-field :rules="[v => !!v || 'Nombre requerido']"  v-model="obra.nombre" label="Nombre"></v-text-field>
						<v-text-field v-model="obra.localidad" label="Localidad"></v-text-field>
						<v-text-field v-model="obra.num_contrato" label="Numero de Contrato"></v-text-field>
						<v-select v-model="obra.sector" label="Sector" :items="sectores" ></v-select>
						<v-text-field v-model="obra.anho" label="Año" ></v-text-field>
						<v-text-field v-model="obra.beneficiarios" label="Numero de Beneficiarios" ></v-text-field>
						<v-btn block color="primary" v-on:click="guardar" >Guardar Datos</v-btn>
					</v-card>
				</v-form>
			</v-col>
			<v-col class="columna" cols=6>
				<v-card-title>Archivos a subir</v-card-title>
				<input id="archivo"  type="file" style="display:none"  v-on:change="setFileSelected" />
				<input id="imagen"  type="file" style="display:none"  v-on:change="setImageSelected" />
				<v-card v-if="estaListo" style="margin:0; padding:20px;" elevation="0">
					<v-card style="margin:10px;padding:0px;" elevation=0 outlined :key="'asdfsd'">
						<v-row :class="'fila '+colorRow('contrato')" >
							<v-col style="margin:0; padding:10px 0 0 10px">{{contratoText}}</v-col>
							<v-col class="columna" cols=1 v-if="!tieneArchivo('contrato')">
								<v-card elevation=0 class="text-center" style="background-color: transparent!important;"
								v-on:click="buscarArchivo('contrato')" :key="'a1'">
									<div><i class="fa fa-upload" ></i></div>
									<div class="text-tini">Subir</div>
								</v-card>
							</v-col>
							<v-col class="columna" cols=1 v-if="tieneArchivo('contrato')">
								<v-card elevation=0 dark class="text-center" style="background-color: transparent!important;"
								v-on:click="borrar('contrato')">
									<div><i class="fa fa-trash" ></i></div>
									<div class="text-tini">Borrar</div>
								</v-card>
							</v-col>
							<v-col class="columna" cols=1 v-if="tieneArchivo('contrato')">
								<v-card elevation=0 dark class="text-center" style="background-color: transparent!important;"
								v-on:click="link('contrato')" :key="'b1'">
									<div><i class="fa fa-link" :key="'c1'"> </i></i></div>
									<div class="text-tini">Link</div>
								</v-card>
							</v-col>
							<v-col class="columna" cols=1 v-if="tieneArchivo('contrato')">
								<v-card elevation=0 dark class="text-center" style="background-color: transparent!important;"
								v-on:click="ver('contrato')" :key="'d1'">
									<div><i class="fa fa-file" ></i></div>
									<div class="text-tini">Ver</div>
								</v-card>
							</v-col>
						</v-row>
					</v-card>
					<v-card style="margin:10px;padding:0px;" elevation=0 outlined v-for="a,index in documentos" :key="index">
						<v-row :class="'fila '+colorRow(a.field)">
							<v-col style="margin:0; padding:10px 0 0 10px" >{{a.titulo}}</v-col>
							<v-col class="columna" cols=1 v-if="!tieneArchivo(a.field)">
								<v-card elevation=0 class="text-center" style="background-color: transparent!important;"
								v-on:click="buscarArchivo(a.field)" :key="index+'a1'">
									<div><i class="fa fa-upload" ></i></div>
									<div class="text-tini">Subir</div>
								</v-card>
							</v-col>
							<v-col class="columna" cols=1 v-if="tieneArchivo(a.field)">
								<v-card elevation=0 dark class="text-center" style="background-color: transparent!important;"
								v-on:click="borrar(a.field)" :key="index+'b1'">
									<div><i class="fa fa-trash" ></i></div>
									<div class="text-tini">Borrar</div>
								</v-card>
							</v-col>
							<v-col class="columna" cols=1 v-if="tieneArchivo(a.field)">
								<v-card elevation=0 dark class="text-center" style="background-color: transparent!important;"
								id="chido" v-on:click="copy('chido', a.field)" :key="index+'c1'">
									<div><i class="fa fa-link" > </i></i></div>
									<div class="text-tini">Link</div>
								</v-card>
							</v-col>
							<v-col class="columna" cols=1 v-if="tieneArchivo(a.field)">
								<v-card elevation=0 dark class="text-center" style="background-color: transparent!important;"
								v-on:click="ver(a.field)" :key="index+'d1'">
									<div><i class="fa fa-file" ></i></div>
									<div class="text-tini">Ver</div>
								</v-card>
							</v-col>
						</v-row>
					</v-card>
				</v-card>
			</v-col>
		</v-row>
		<v-divider></v-divider>
		<v-card-title>Imagenes de la Obra</v-card-title>
		<v-row v-if="!!obra.id">
			<Galeria v-on:agregar="agregarImagen" v-on:borrar="borrarImagen" :imagenes="obra.imagenes"/>
		</v-row>
		<v-divider></v-divider>
		
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn color="red" dark v-on:click="cerrar">Cerrar creacion de Obra</v-btn>
		</v-card-actions>
		
		<v-snackbar v-model="snackbar" :vertical="true" :timeout="2000" >
			Link Copiado 
 			<template v-slot:action="{ attrs }">
				<v-btn color="indigo" text v-bind="attrs"
				@click="snackbar = false" >
				Cerrar
				</v-btn>
			</template>
		</v-snackbar>
	</v-card>
</template>

<script>
import Galeria from '../../generales/Galeria';
import Archivo from '../../models/Archivo'
import Obra from '../../models/Obra'
import Helper from '../../utils/Helper';
export default {
  components:{Galeria},
  props: {
		obra: {
			type:Object,
			default() {return {
				id:null,
				nombre:null,
				localidad:null,
				num_contrato:null,
				sector:null,
				anho:null,
				beneficiarios:null,
				admin_directa:false,
				contrato_id:null,
				acta_contratista_id:null,
				acta_comite_id:null,
				fianza_cumplimiento_id:null,
				fianza_vicios_id:null,
				fianza_anticipo_id:null,
				contrato:null,
				acta_contratista:null,
				acta_comite:null,
				fianza_cumplimiento:null,
				fianza_vicios:null,
				fianza_anticipo:null,
				imagenes:[],
			};}
		},
		plan: {
			type:Object,
			default() {return null;}
		},
		obras: {
			type:Array,
			default() {return [];}
		},
		index: {
			type:Number,
			default() {return null;}
		},
	},
  data () { return {
		secciones:[],
		snackbar:false,
		valid:false,
		file:null,
		overlay:true,
		campoASubir:null,
		sectores:['Agua y Saneamiento','Desarrollo Urbano','Educacion','Salud','Vivienda','Urbanizacion','Electrificacion','Otros'],
		documentos: [
			{
				titulo:'Acta Entrega Recepcion del Contratista al Municipio',
				field:'acta_contratista',
			},
			{
				titulo:'Acta Entrega Recepcion del Municipio al Comite de Obras',
				field:'acta_comite',
			},
			{
				titulo:'Fianza de Cumplimiento',
				field:'fianza_cumplimiento',
			},
			{
				titulo:'Fianza de Vicios Ocultos',
				field:'fianza_vicios',
			},
			{
				titulo:'Fianza Anticipo',
				field:'fianza_anticipo',
			},
		],
	}},
	mounted() {
		if(!!this.obra.id) {
			let p = new Obra();
			this.$store.commit('setsplash',{activar:true,texto:'Cargando...'});
			p.ver(this.obra.id).then(response => {
				Object.assign(this.obra,response.data);
				this.$store.commit('setsplash',{activar:false,texto:''});
			});
		}
	},
	methods: {
		ver(campo) {
			window.open(this.obra[campo].url , '_blank');
		},
    copy(id, campo) {
			Helper.copyClipboard(document.getElementById(id), this.obra[campo].url).then(() => {  alert("Link copiado"); })
		},
		agregarImagen() {
			document.getElementById("imagen").click();
		},
		borrarImagen(i,index) {
			let a= new Archivo ;
			this.$store.commit('setsplash',{activar:true,texto:'Subiendo archivo...'});
			a.borrarImagenObra(this.obra.id,i.id).then( response => { 
				if(!!response.data) {
					this.obra.imagenes.splice(index,1);
				}
				this.$store.commit('setsplash',{activar:false,texto:''});
			});
		},
		guardar() {
			if(this.$refs.form.validate()) {
				let p = new Obra();
				this.$store.commit('setsplash',{activar:true,texto:'Cargando...'});
				p.guardar(this.obra).then(response => {
					let nueva = !this.obra.id;
					Object.assign(this.obra,response.data);
					if(nueva) {
						this.obras.push(this.obra);
					}
					this.$store.commit('setsplash',{activar:false,texto:''});
					//this.cerrar();
				});
			} else {
				console.log('relleno los campos minimos')
			}			
		},
		borrar(campo) {
			let a= new Archivo ;
			this.$store.commit('setsplash',{activar:true,texto:'Subiendo archivo...'});
			a.borrarArchivoObra(campo,this.obra.id).then( response => { 
				Object.assign(this.obra,response.data);
				this.$store.commit('setsplash',{activar:false,texto:''});
			});
		},
		buscarArchivo(campo) {
			this.campoASubir = campo;
			document.getElementById("archivo").click();
		},
		setFileSelected(event) {
			this.file = event.target.files;
			let a= new Archivo ;
			let formData = new FormData();
			formData.append('obra_id', this.obra.id);
			formData.append('archivo', this.file[0]);
			formData.append('campo', this.campoASubir);
			this.$store.commit('setsplash',{activar:true,texto:'Subiendo archivo...'});
			a.agregarArchivoObra(formData).then(response => {
				Object.assign(this.obra,response.data);
				this.$store.commit('setsplash',{activar:false,texto:''});
			}); 
		},
		setImageSelected(event) {
			this.file = event.target.files;
			let a= new Archivo ;
			let formData = new FormData();
			formData.append('obra_id', this.obra.id);
			formData.append('imagen', this.file[0]);
			this.$store.commit('setsplash',{activar:true,texto:'Subiendo archivo...'});
			a.agregarImagenObra(formData).then(response => {
				this.obra.imagenes.push(response.data);
				this.$store.commit('setsplash',{activar:false,texto:''});
			}); 
		},
		cerrar() {
			this.$emit('cerrar');
		},
		tieneArchivo(file_id) {
			return !!this.obra[file_id+'_id'];
		},
		colorRow(file_id) {
			return this.tieneArchivo(file_id)?'fondo-verde':'fondo-gris'
		},
	},
	computed: {
		contratoText() {
			return this.obra.admin_directa?'Acta de adjudicacion directa':'Contrato';
		},
		estaListo() {
			return this.obra.id?true:false;
		}
	},
	watch: { },
}
</script>

<style>
	.fondo-verde {
		background-color:#029001!important;
		color:#FFFFFF;
	}
	.fondo-gris {
		background-color:#E9E9E9!important;
	}
</style>
