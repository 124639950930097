<template>
	<v-card>
		<v-app-bar dark color="pink" tile><v-card-title>Transparencia</v-card-title></v-app-bar>
    <v-container>
      <v-row>
        <v-col><v-chip>Articulo seleccionado:</v-chip></v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select outlined dense tile :items="articulos" v-model="articulo" :item-text="'nombre'" return-object ></v-select>
        </v-col>
        <v-col><v-btn v-on:click="bloqueModal = true" block color="success">Agregar Bloque de Año</v-btn></v-col>
      </v-row>
      <v-tabs v-model="tab2">
        <v-tab v-for="anho in anhos" :key="anho">{{anho}}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab2">
        <v-tab-item v-for="anho in anhos" :key="anho + 'aa'">
          <FraccionesFormList :anho="anho" :articulo="articulo.articulo" :key="anho + 'bb'"/>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
		<!-- -->
		<v-dialog width="300" v-model="bloqueModal" persistent>
			<v-card style="margin:0">
				<v-toolbar dark  elevation="0" class="cabecera-azul">
					<v-btn text v-on:click="cerrarForm"><i class="fa fa-times"></i></v-btn>
				</v-toolbar>
				<v-container>
					<v-row>
						<v-col>Año a Agregar</v-col>
						<v-col> <v-text-field v-model="anhoBloque" type="number"></v-text-field></v-col>
					</v-row>
					<v-card-actions>
						<v-btn color="primary" v-on:click="crearBloque">Crear</v-btn>
						<v-btn color="red" dark v-on:click="cerrarForm">Cancelar</v-btn>
					</v-card-actions>
				</v-container>
			</v-card>
		</v-dialog>
	</v-card>
</template>

<script>
import FraccionesFormList from '../reutilizables/fracciones/FraccionesFormList'
import Fraccion from '../models/Fraccion'
import Helper from '../utils/Helper'
export default {
  components:{FraccionesFormList},
  data () { return { 
		tab2:0,
		articulos:[
      {articulo: 70, nombre: 'Articulo 70'},
      {articulo: 71, nombre: 'Articulo 71'},
      {articulo: 30, nombre: 'Articulo 30'},
      {articulo: 36, nombre: 'Articulo 36'}
    ],
		articulo: null,
		anhoBloque: null,
		anho: null,
		bloqueModal: false,
		anhos:[],
	}},
	mounted() {
		this.articulo = this.articulos[0];
	},
	methods: {
		cargarAnhos() {
			let f = new Fraccion();
			this.$store.commit('setsplash',{activar:true,texto:'Cargando datos...'});
			f.anhosFraccion(this.articulo.articulo).then(response => {
				this.anhos = response.data;
				this.$store.commit('setsplash',{activar:false,texto:''});
			});
		},
		crearBloque() {
			if(!Helper.esNumero(this.anhoBloque)) {
				return;
			}
			if(this.anhos.includes(Number(this.anhoBloque))) {
				alert("ya esta registrado este año");
				return;
			}
			let f = new Fraccion();
			this.$store.commit('setsplash',{activar:true,texto:'Generando Informacion...'});
			f.crearBloque(this.articulo.articulo, this.anhoBloque).then(response => {
				this.anhos = response.data;
				this.cerrarForm();
				this.$store.commit('setsplash',{activar:false,texto:''});
			});
		},
		cerrarForm() {
			this.anhoBloque = false;
			this.bloqueModal = false;
		},
	},
	computed: { },
	watch: {
		articulo(value) {
			this.cargarAnhos();
		},
		tab2() {
			this.anho = this.anhos[this.tab2];
		}
	},
}
</script>

<style>
</style>
