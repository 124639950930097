<template>
	<v-container>
		<input :id="'archivo'+anho"  type="file" style="display:none"  v-on:change="setFileSelected"/>
		<v-expansion-panels>
			<v-expansion-panel v-for="(normas,conjunto,index) in normasPorConjunto" :key="conjunto">
				<v-expansion-panel-header>{{conjunto}}</v-expansion-panel-header>
				<v-expansion-panel-content>
					<v-btn style="margin:10px" v-on:click="modalNorma(index)"> Agregar elemento</v-btn>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left"> Nombre</th>
                  <th class="text-center" width="110px"> Subir Archivo</th>
                  <th class="text-left"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="f, index in normas" :key="index">
                  <td>{{ f.nombre }}</td>
                  <td class="text-center"><v-btn x-small color="success" v-on:click="modalArchivo(f)" ><i class="fa fa-upload"></i></v-btn></td>
                  <td>
                    <v-simple-table tile>
                      <template v-slot:default>
                        <tbody>
                          <tr v-for="a, index2 in f.archivos" :key="a.id">
                            <td>{{ a.nombre }}</td>
                            <td width="60px">{{ a.archivo.tipo }}</td>
                            <td class="text-center" width="60px"><v-btn x-small outlined id="chido" v-on:click="copy('chido', a)"><i class="fa fa-link"></i></v-btn></td>
                            <td class="text-center" width="60px"><v-btn x-small outlined v-on:click="ver(a)"><i class="fa fa-file"></i></v-btn></td>
                            <td class="text-center" width="60px"><v-btn x-small outlined v-on:click="borrar(a,f)"><i class="fa fa-trash"></i></v-btn></td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
		<!-- -->
		<v-dialog width="500" v-model="archivoModal" persistent>
			<v-card style="margin:0" v-if="!!normaSelect">
				<v-toolbar dark  elevation="0" class="cabecera-azul">
					<v-btn text v-on:click="cerrarArchivoModal"><i class="fa fa-times"></i></v-btn><v-spacer></v-spacer>{{normaSelect.nombre}}
				</v-toolbar>
				<v-container>
					<v-row>
						<v-col>
							<v-form ref="form">
								<v-text-field v-model="nombreArchivo" label="Nombre del Archivo(Trimestre, Semestre, etc..)" :rules="nombreRule"></v-text-field>
							</v-form>
							
						</v-col>
					</v-row>
					<v-row>
						<v-col><v-btn v-on:click="buscarArchivo()">Buscar Archivo</v-btn></v-col>
						<v-col v-if="!!file">{{file.name}}</v-col>
					</v-row>
					<v-card-actions>
						<v-btn color="primary" v-on:click="guardarArchivo" :disabled="deshabilitarCrear">Crear</v-btn>
						<v-btn color="red" dark v-on:click="cerrarArchivoModal">Cancelar</v-btn>
					</v-card-actions>
				</v-container>
			</v-card>
		</v-dialog>
		<!-- -->
		<v-dialog width="500" v-model="normaModal" persistent>
			<v-card style="margin:0" v-if="normaModal">
				<v-toolbar dark  elevation="0" class="cabecera-azul">
					<v-btn text v-on:click="cerrarNormaModal"><i class="fa fa-times"></i></v-btn><v-spacer></v-spacer>Nuevo Elemento
				</v-toolbar>
				<v-container>
					<v-row>
						<v-col>
							<v-form ref="form2">
								<v-text-field v-model="nombreNorma" label="Nombre del nuevo elemento" :rules="nombreRule"></v-text-field>
							</v-form>
						</v-col>
					</v-row>
					<v-card-actions>
						<v-btn color="primary" v-on:click="guardarNorma" :disabled="deshabilitarCrearNorma">Guardar</v-btn>
						<v-btn color="red" dark v-on:click="cerrarNormaModal">Cancelar</v-btn>
					</v-card-actions>
				</v-container>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
import Norma from '../../models/Norma';
import Helper from '../../utils/Helper';
export default {
  components:{},
  props: {
		anho: {
			type:Number,
			default() {return 1;}
		},
	},
  data () { return { 
		normas: [],
		archivoModal:false,
		normaModal:false,
		deshabilitarCrear:false,
		deshabilitarCrearNorma:false,
		normaSelect:null,
		conjuntoSelect:null,
		file:null,
		nombreNorma:null,
		nombreArchivo:null,
		nombreRule:[
			val => !!val || 'Debes escribir el nombre',
		],
	}},
	mounted() {
		this.cargarNormas();
	},
	methods: {
		modalArchivo(f) {
			this.normaSelect = f;
			this.archivoModal = true;
		},
		modalNorma(f) {
			this.conjuntoSelect = f;
			console.log("norma modal", this.conjuntoSelect);
			this.normaModal = true;
		},
		cerrarArchivoModal() {
			this.normaSelect = null;
			//this.nombreArchivo = null;
			this.file = null;
			this.archivoModal = false;
		},
		cerrarNormaModal() {
			this.nombreNorma = null;
			this.normaModal = false;
		},
		guardarNorma() {
			if(!this.$refs.form2.validate()) {
				return;
			}
			let a = new Norma();
			this.deshabilitarCrearNorma = true;
			this.$store.commit('setsplash',{activar:true,texto:'Subiendo archivo...'});
			a.guardar({
				nombre: this.nombreNorma,
				conjunto_id: Number(this.conjuntoSelect) + 1,
				anho: this.anho,
			}).then(response => {
				console.log("guardando dara",response);
				this.normas.push(response.data);
				this.cerrarNormaModal();
				this.$store.commit('setsplash',{activar:false,texto:''});
				this.deshabilitarCrearNorma = false;
			}); 
		},
		guardarArchivo() {
			if(!this.$refs.form.validate()) {
				return;
			}
			if(!this.file) {
				alert("Debes de Seleccionar un archivo");
				return;
			}
			this.deshabilitarCrear = true;
			let a= new Norma() ;
			let formData = new FormData();
			formData.append('norma_id', this.normaSelect.id);
			formData.append('archivo', this.file);
			formData.append('nombre', this.nombreArchivo);
			this.$store.commit('setsplash',{activar:true,texto:'Subiendo archivo...'});
			a.agregarArchivo(formData).then(response => {
				this.normaSelect.archivos = response.data;
				this.cerrarArchivoModal();
				this.$store.commit('setsplash',{activar:false,texto:''});
				this.deshabilitarCrear = false;
			}); 
		},
    copy(id, a) {
			Helper.copyClipboard(document.getElementById(id), a.archivo.url).then(() => { alert("Link copiado");})
		},
		ver(a) {
			console.log(a.archivo.tipo);
			let tipos = ['xlsx','xls','xlsm'];
			if(tipos.includes(a.archivo.tipo)) {
				window.open('https://view.officeapps.live.com/op/view.aspx?src='+a.archivo.url , '_blank');
			} else {
				window.open(a.archivo.url , '_blank');
			}
		},
		borrar(a,norma) {
			if(!confirm("Se eliminara el archivo")) {
				return;
			}
			let f = new Norma();
			console.log("borrando",a);
			this.$store.commit('setsplash',{activar:true,texto:'Borrando archivo...'});
			f.borrarArchivo(a.id).then(response => {
				console.log("borrar", response.data);
				norma.archivos = response.data;
				this.$store.commit('setsplash',{activar:false,texto:''});
			});
		},
		buscarArchivo(campo) {
			//this.$store.commit('setsplash',{activar:true,texto:'Buscando ...'});
			document.getElementById("archivo"+this.anho).click();
		},
		setFileSelected(event) {
			this.file = event.target.files[0];
			console.log("archivo select",this.file);
			//this.$store.commit('setsplash',{activar:false,texto:''});
		},
		cargarNormas() {
			let f = new Norma();
			this.$store.commit('setsplash',{activar:true,texto:'Cargando archivo...'});
			f.listarNormas(this.anho).then(response => {
				this.normas = response.data;
				this.$store.commit('setsplash',{activar:false,texto:''});
			});
		},
	},
	computed: {
		normasPorConjunto() {
			let conjuntos =  {
				'INFORMACION FINANCIERA':[],
				'INFORMACION DE LEY DE INGRESOS Y PRESUPUESTO DE EGRESOS':[],
				'CALENDARIO':[],
				'EJERCICIO DEL PRESUPUESTO':[],
				'EVALUACION Y RENDICIÓN DE CUENTAS':[],
				'LEY DE DISCIPLINA FINANCIERA':[],
			};
			this.normas.forEach((item) => {
				switch(item.conjunto_id) {
					case 1:conjuntos['INFORMACION FINANCIERA'].push(item); break;
					case 2:conjuntos['INFORMACION DE LEY DE INGRESOS Y PRESUPUESTO DE EGRESOS'].push(item); break;
					case 3:conjuntos['CALENDARIO'].push(item); break;
					case 4:conjuntos['EJERCICIO DEL PRESUPUESTO'].push(item); break;
					case 5:conjuntos['EVALUACION Y RENDICIÓN DE CUENTAS'].push(item); break;
					case 6:conjuntos['LEY DE DISCIPLINA FINANCIERA'].push(item); break;
				};
			});
			return conjuntos;
		},
	},
	watch: {
		anho() {
			this.cargarNormas();
		},
	},
}
</script>

<style>
</style>
