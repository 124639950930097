<template>
	<v-card>
		<v-app-bar dark color="pink" tile><v-card-title>Contraloria</v-card-title></v-app-bar>
	</v-card>
</template>

<script>
export default {
  components:{},
  data () { return { }},
	mounted(){},
	methods: {},
	computed: {},
	watch: {},
}
</script>

<style>
</style>
