
import store from './store';

//import Materia from '../models/Materia';

export default {
	
	async start() {
	},
}

