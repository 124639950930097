<template>
	<v-card>
		<v-app-bar dark color="pink" tile>
			<v-card-title>Obras</v-card-title> 
			<v-spacer></v-spacer>
			<v-btn color="indigo" v-on:click="nuevo">Crear Obra</v-btn>
		</v-app-bar>
		<v-card-text>
			<v-data-table :headers="cabecera" :items="obras"  class="elevation-1" >
				<template v-slot:item.acciones="{ item }">
					<v-btn text @click="editar(item)" ><i class="fa fa-edit"></i></v-btn>
					<v-btn text><i class="fa fa-trash"></i></v-btn>
				</template>
			</v-data-table>
		</v-card-text>
		<!-- -->
		<v-dialog v-model="obraModal" persistent fullscreen width="800px">
			<ObraForm v-if="!!obraSelect" :obra="obraSelect" :obras="obras" :index="index" v-on:cerrar="cerrar"/>
			<ObraForm v-else-if="obraModal" :obras="obras" v-on:cerrar="cerrar"/>
		</v-dialog>
		<!-- -->
	</v-card>
</template>

<script>
import Obra from '../models/Obra'
import ObraForm from '../reutilizables/obras/ObraForm'
export default {
  components:{ObraForm},
  data () { return { 
		obras:[],
		cabecera:[
			{ text: 'Nombre', value: 'nombre' },
      { text: 'Localidad', value: 'localidad' },
      { text: 'Fecha', value: 'created_at' },
      { text: 'Sector', value: 'sector' },
      { text: 'Año', value: 'anho' },
      { text: 'Acciones', value: 'acciones', sortable: false },
		],
		obraModal:false,
		index: null,
		obraSelect: null,
	}},
	mounted() {
		let p = new Obra();
		this.$store.commit('setsplash',{activar:true,texto:'Cargando...'});
		p.listar().then(response => {
			this.obras = response.data;
			this.$store.commit('setsplash',{activar:false,texto:''});
		});
		this.$store.commit('setsplash',{activar:false,texto:'Cargando...'});
	},
	methods: {
		nuevo() {
			this.index= null;
			this.obraSelect= null;
			this.obraModal= true;
		},
		editar(i) {
			this.obraSelect= i;
			this.obraModal= true;
		},
		cerrar() {
			this.index= null;
			this.obraSelect= null;
			this.obraModal= false;
		}
	},
	computed: {},
	watch: {},
}
</script>

<style>
</style>
