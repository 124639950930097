<template>
	<v-app style=""> 
		<v-navigation-drawer app class="navigation">
			<div style="height:65px; background-color:#404040; color:#FFFFFF; padding:10px;"> </div>
			<v-divider></v-divider>
			<v-list>
				<v-list-item-group  multiple>
					<template v-for="(item, i) in itemsMenu" >
						<v-list-item :key="i" v-on:click="ir(item)">
							<v-row class="fila" style="color:#FFFFFF">
								<v-col class="columna" cols="3">
									<i :class="item.icon"></i>
								</v-col>
								<v-col class="columna" cols="9">
									{{item.nombre}}
								</v-col>
							</v-row>
						</v-list-item>
						<v-divider :key="`divider-${i}`"></v-divider>
					</template>					
					<template>
						<v-list-item :key="'cerrar'" v-on:click="cerrarSesion">
							<v-row class="fila" style="color:#FFFFFF">
								<v-col class="columna" cols="3">
									<i class="fa fa-times"></i>
								</v-col>
								<v-col class="columna" cols="9">
									Cerrar Sesion
								</v-col>
							</v-row>
						</v-list-item>
						<v-divider :key="'divider-cerr'"></v-divider>
					</template>					
				</v-list-item-group>
			</v-list>
		</v-navigation-drawer>
		
		<v-main >
			<v-window vertical v-model="window">
				<!-- vistas principales-->
				<v-window-item>  <NoticiasIndex v-if="window == 0"/> </v-window-item>
				<v-window-item>  <TransparenciaIndex v-if="window == 1"/></v-window-item>
				<v-window-item>  <ObrasIndex v-if="window == 2"/> </v-window-item>
				<v-window-item>  <GacetaIndex v-if="window == 3"/> </v-window-item>
				<v-window-item>  <ContraloriaIndex v-if="window == 4"/> </v-window-item>
				<v-window-item>  <CONACIndex v-if="window == 5"/> </v-window-item>
				<v-window-item>  <Configuraciones v-if="window == 6"/> </v-window-item>
			</v-window>
		</v-main>
	</v-app>
</template>

<script>
	import NoticiasIndex from './NoticiasIndex';
	import TransparenciaIndex from './TransparenciaIndex';
	import ObrasIndex from './ObrasIndex';
	import GacetaIndex from './GacetaIndex';
	import ContraloriaIndex from './ContraloriaIndex';
	import CONACIndex from './CONACIndex';
	import Configuraciones from './Configuraciones';
	import User from '../models/User';
	import Config from '../models/Config';
export default {
  components:{
		NoticiasIndex,
		TransparenciaIndex,
		ObrasIndex,
		GacetaIndex,
		ContraloriaIndex,
		CONACIndex,
		Configuraciones
	},
  data () { return { 
		window:0,
		itemsMenu: [
			{ icon: 'fa fa-newspaper', nombre: 'Noticias', window: 0},
			{ icon: 'fa fa-book-open', nombre: 'Transparencia', window: 1},
			{ icon: 'fa fa-hard-hat', nombre: 'Obras', window: 2},
			{ icon: 'fa fa-rss-square', nombre: 'Gaceta', window: 3},
			{ icon: 'fa fa-landmark', nombre: 'Contraloria', window: 4},
			{ icon: 'fa fa-archive', nombre: 'CONAC', window: 5},
			{ icon: 'fa fa-enginie', nombre: 'Configuraciones', window: 6}
		],
	}},
	mounted () {
		this.checkAuth();
	},
	methods: {
		checkAuth () {
			let id = localStorage.getItem('user_id');
			if(!!id) {
				let u = new User();
				//let c = new Config();
				u.ver(id).then((response) => {
					//console.log(response.data);
					this.$store.commit('setuser',response.data);
					//c.listar().then(response => {});
					this.listo = true;
				});
				return;
			} 
			this.$router.push('/login').catch(err => {
				// Ignore the vuex err regarding  navigating to the page they are already on.
				if ( err.name !== 'NavigationDuplicated' && !err.message.includes('Avoided redundant navigation to current location') ) {
					// But print any other errors to the console
					logError(err);
				}
			});
			this.listo = true;
		},
		ir (item) {
			this.window = item.window;
		},
		cerrarSesion () {
			this.$router.push('/');
		}
	},
	computed: {},
	watch: {},
}
</script>

<style>
</style>
