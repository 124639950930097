import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
		user: null,
		splash: false,
		config: null,
		splashText: null,
		window: 0,
		data: null,
		rutas: [],
		config: {},
	},
  mutations: {
		setrutas (state, d) {
			state.rutas = d;
		},
		router (state,d) {
			state.window = d.window;
			state.data = d.data?d.data:null;
			state.rutas.push(d);
		},
		setuser (state, d) { 
			state.user = d;
		},
		setserverdata (state, d) { 
			state.data_server = d;
		},
		setsplash (state, d) {
			state.splash = d.activar;
			state.splashText = d.texto;
		},
    setconfig (state, data) {
      let obj = {};
      data.forEach((conf) => {
        obj[conf.campo] = conf.valor
      })
      state.config = obj;
    }
	}
})
