<template>
  <v-container>
    <input :id="'archivo'+anho+''+articulo"  type="file" style="display:none"  v-on:change="setFileSelected"/>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left"> Fraccion</th>
            <th class="text-center" width="110px"> Subir Archivo</th>
            <th class="text-left"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="f, index in fracciones" :key="index">
            <td>{{ f.nombre }}</td>
            <td class="text-center"><v-btn x-small color="success" v-on:click="modalArchivo(f)" ><i class="fa fa-upload"></i></v-btn></td>
            <td>
              <v-simple-table tile>
                <template v-slot:default>
                  <tbody>
                    <tr v-for="a, index2 in f.archivos" :key="a.id">
                      <td>{{ a.nombre }}</td>
                      <td>{{ a.archivo.nombre_archivo }}</td>
                      <td class="text-center"><v-btn x-small outlined id="chido" v-on:click="copy('chido', a)"><i class="fa fa-link"></i></v-btn></td>
                      <td class="text-center"><v-btn x-small outlined v-on:click="ver(a)"><i class="fa fa-file"></i></v-btn></td>
                      <td class="text-center"><v-btn x-small outlined v-on:click="borrar(a,f)"><i class="fa fa-trash"></i></v-btn></td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-dialog width="500" v-model="archivoModal" persistent>
			<v-card style="margin:0" v-if="!!fraccionSelect">
				<v-toolbar dark  elevation="0" class="cabecera-azul">
					<v-btn text v-on:click="cerrarArchivoModal"><i class="fa fa-times"></i></v-btn><v-spacer></v-spacer>{{fraccionSelect.nombre}}
				</v-toolbar>
				<v-container>
					<v-row>
						<v-col>
							<v-form ref="form">
								<v-text-field v-model="nombreArchivo" label="Nombre del Archivo" :rules="nombreRule"></v-text-field>
							</v-form>
							
						</v-col>
					</v-row>
					<v-row>
						<v-col><v-btn v-on:click="buscarArchivo()">Buscar Archivo</v-btn></v-col>
						<v-col v-if="!!file">{{file.name}}</v-col>
					</v-row>
					<v-card-actions>
						<v-btn color="primary" v-on:click="guardarArchivo" :disabled="deshabilitarCrear">Crear</v-btn>
						<v-btn color="red" dark v-on:click="cerrarArchivoModal">Cancelar</v-btn>
					</v-card-actions>
				</v-container>
			</v-card>
		</v-dialog>
  </v-container>
</template>

<script>
import Fraccion from '../../models/Fraccion';
import Helper from '../../utils/Helper';
export default {
  components:{},
  props: {
		articulo: {
			type:Number,
			default() {return 1;}
		},
		anho: {
			type:Number,
			default() {return 1;}
		},
	},
  data () { return { 
		fracciones: [],
		archivoModal:false,
		deshabilitarCrear:false,
		fraccionSelect:null,
		file:null,
		nombreArchivo:null,
		nombreRule:[
			val => !!val || 'Debes escribir el nombre',
		],
	}},
	mounted() {
		this.cargarFracciones();
	},
	methods: {
		modalArchivo(f) {
			this.fraccionSelect = f;
			this.archivoModal = true;
		},
		cerrarArchivoModal() {
			this.fraccionSelect = null;
			this.nombreArchivo = null;
			this.file = null;
			this.archivoModal = false;
		},
		guardarArchivo() {
			if(!this.$refs.form.validate()) {
				return;
			}
			if(!this.file) {
				alert("Debes de Seleccionar un archivo");
				return;
			}
			this.deshabilitarCrear = true;
			let a= new Fraccion() ;
			let formData = new FormData();
			formData.append('fraccion_id', this.fraccionSelect.id);
			formData.append('archivo', this.file);
			formData.append('nombre', this.nombreArchivo);
			this.$store.commit('setsplash',{activar:true,texto:'Subiendo archivo...'});
			a.agregarArchivo(formData).then(response => {
				this.fraccionSelect.archivos = response.data;
				this.cerrarArchivoModal();
				this.$store.commit('setsplash',{activar:false,texto:''});
				this.deshabilitarCrear = false;
			}); 
		},
		copy(id, a) {
			Helper.copyClipboard(document.getElementById(id), a.archivo.url).then(() => { alert("Link copiado"); })
		},
		ver(a) {
			console.log(a.archivo.tipo);
			let tipos = ['xlsx','xls','xlsm'];
			if(tipos.includes(a.archivo.tipo)) {
				window.open('https://view.officeapps.live.com/op/view.aspx?src='+a.archivo.url , '_blank');
			} else {
				window.open(a.archivo.url , '_blank');
			}
		},
		borrar(a,fraccion) {
			if(!confirm("Se eliminara el archivo")) {
				return;
			}
			let f = new Fraccion();
			console.log("borrando",a);
			this.$store.commit('setsplash',{activar:true,texto:'Borrando archivo...'});
			f.borrarArchivo(a.id).then(response => {
				console.log("borrar", response.data);
				fraccion.archivos = response.data;
				this.$store.commit('setsplash',{activar:false,texto:''});
			});
		},
		buscarArchivo(campo) {
			//this.$store.commit('setsplash',{activar:true,texto:'Buscando archivo...'});
			document.getElementById("archivo"+this.anho+''+this.articulo).click();
		},
		setFileSelected(event) {
			this.file = event.target.files[0];
			console.log("archivo select",this.file);
			//this.$store.commit('setsplash',{activar:false,texto:''});
		},
		cargarFracciones() {
			let f = new Fraccion();
			this.$store.commit('setsplash',{activar:true,texto:'Cargando archivo...'});
			f.listarFracciones(this.articulo, this.anho).then(response => {
        console.log(response)
				this.fracciones = response.data;
				this.$store.commit('setsplash',{activar:false,texto:''});
			});
		},
	},
	computed: {},
	watch: {
		articulo() {
			this.cargarFracciones();
		},
		anho() {
			this.cargarFracciones();
		},
	},
}
</script>

<style>
</style>
