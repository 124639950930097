<template>
	<v-app style=""> 
		<NavBar />
		<v-main class="principal">
      <v-card-title>Gaceta Municipal de {{ municipio }}</v-card-title>
			<v-tabs v-model="tab2">
				<v-tab>2019</v-tab>
				<v-tab>2021</v-tab>
				<v-tab>2022</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tab2">
				<v-tab-item>
					<v-row class="fila cabecera-gris">
						<v-col class="columna text-center" cols=1>No.</v-col>
						<v-col class="columna " cols=8>Gaceta</v-col>
						<v-col class="columna text-center" cols=3>Descargar</v-col>
					</v-row>
					<v-divider></v-divider>
					<div v-for="g,index in gacetas" :key="index">
						<v-row class="fila">
							<v-col class="columna text-center" cols=1>{{g.id}}</v-col>
							<v-col class="columna" cols=8>
								<div><a>{{g.nombre}}</a></div>
								<div>{{g.fecha}}</div>
							</v-col>
							<v-col class="columna text-center" cols=3>
								<v-btn><i class="fa fa-file"></i></v-btn>
							</v-col>
						</v-row>
						<v-divider></v-divider>
					</div>
				</v-tab-item>
			</v-tabs-items>
		</v-main>
		<Footer />
	</v-app>
</template>

<script>
import NavBar from '../generales/NavBar';
import Footer from '../generales/Footer';
export default {
  components:{NavBar,Footer},
  data () { return { 
		tab2:0,
		gacetas:[
			{id:9,nombre:'Lista de asistencia Sesión Pública y Solemne de Cabildo',fecha:'Lunes, 12 Octubre 20219'},
			{id:10,nombre:'Lista de asistencia XXXVII Sesión Extraordinaria de Cabildo',fecha:'Lunes, 31 Octubre 20219'},
			{id:11,nombre:'Lista de asistencia Sesión Pública y Solemne de Cabildo',fecha:'Lunes, 11 Noviembre 20219'},
		],
	}},
	mounted(){},
	methods: {},
	computed: {
    municipio () {
      return !this.$store.state.config['Nombre Municipio']? '': this.$store.state.config['Nombre Municipio']
    }
  },
	watch: {},
}
</script>

<style>
</style>
