<template>
	<v-app style=""> 
		<NavBar />
		<v-main>
      <v-card-title>Obras Publicas</v-card-title>
			<v-tabs v-model="tab2">
				<v-tab v-for="a,index in anhos" :key="index">{{a.anho}}</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tab2" style="margin:0;padding:20px">
				<v-tab-item v-for="a,index in anhos" :key="index" >
					<v-expansion-panels >
						<v-expansion-panel v-for="obras,sector in obrasPorSector" :key="sector" class="cabecera-gris">
							<v-expansion-panel-header>{{sector}}</v-expansion-panel-header>
							<v-expansion-panel-content>
								<v-row class="fila">
									<v-col class="columna text-center" cols=3 v-for="o,index in obras" :key="sector + '' + index" >
										<v-hover  v-slot="{ hover }"  close-delay="200" >
											<v-card :elevation="hover ? 16 : 0" :class="{ 'on-hover': hover }" style="background-color:transparent!important;"
											v-on:click="verObra(o)">
												<div>
													<v-avatar color="indigo" size="150" > 
														<v-img :src="portadaObra(o)"></v-img>
													</v-avatar>
												</div>
												<div>
													<v-card-text>{{o.nombre}}</v-card-text>
												</div>
											</v-card>
										</v-hover>										
									</v-col>
								</v-row>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
				</v-tab-item>
			</v-tabs-items>
		</v-main>
		<Footer />
	</v-app>
</template>

<script>
import NavBar from '../generales/NavBar';
import Footer from '../generales/Footer';
import Obra from '../models/Obra';
export default {
  components:{NavBar,Footer},
  data () { return { 
		tab2:0,
		anhos:[],
		obras: [],
	}},
	mounted() {
		let o = new Obra();
		this.$store.commit('setsplash',{activar:true,texto:'Cargando informacion...'});
		o.anhosObras().then(response => {
			this.anhos = response.data;
			this.$store.commit('setsplash',{activar:false,texto:''});
			this.buscarObras();
		});
	},
	methods: {
		verObra(o) {
			this.$router.push('/obras/ver/'+o.id);
		},
		buscarObras() {
			if(!this.anhos || this.anhos.length <= 0) {
				return;
			}
			let anho = this.anhos[this.tab2].anho;
			let o = new Obra();
			this.$store.commit('setsplash',{activar:true,texto:'Buscando Obras...'});
			o.buscarPor('anho',anho).then(response => {
				this.obras = response.data;
				console.log("buscar obras",this.obras);
				this.$store.commit('setsplash',{activar:false,texto:''});
			});
		},
		portadaObra(o) {
			return o.imagenes.length > 0? o.imagenes[o.imagenes.length - 1].archivo.url:'';
		},
	},
	computed: {
		obrasPorSector() {
			let data = {};
			this.obras.forEach((o) => {
				if(!data[o.sector]) {
					data[o.sector] = [];
				}
				data[o.sector].push(o);
			});
			console.log("obras por sector",data);
			return data;
		},
	},
	watch: {
		tab2(value) {
			this.buscarObras();
		},
	},
}
</script>

<style>
</style>
