<template>
	<v-app style=""> 
		<NavBar />
		<v-main >
      <v-card-title>CONAC</v-card-title>
			<v-tabs v-model="tab2">
				<v-tab v-for="anho in anhos" :key="anho">{{anho}}</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tab2">
				<v-tab-item v-for="anho in anhos" :key="anho + 'aa'">
					<NormasList :anho="anho" :key="anho + 'bb'"/>
				</v-tab-item>
			</v-tabs-items>
		</v-main>
		<Footer />
	</v-app>
</template>

<script>
import NavBar from '../generales/NavBar';
import Footer from '../generales/Footer';
import NormasList from '../reutilizables/normas/NormasList'
import Norma from '../models/Norma'
import Helper from '../utils/Helper'
export default {
  components:{NavBar, Footer, NormasList},
  data () { return {
		tab2:0,
		anhoBloque: null,
		anho: null,
		bloqueModal: false,
		anhos:[],
	}},
	mounted() {
		this.cargarAnhos();
	},
	methods: {
		cargarAnhos() {
			let f = new Norma();
			this.$store.commit('setsplash',{activar:true,texto:'Cargando datos...'});
			f.anhosNorma().then(response => {
				this.anhos = response.data;
				this.$store.commit('setsplash',{activar:false,texto:''});
			});
		},
	},
	computed: {},
	watch: {
		tab2() {
			this.anho = this.anhos[this.tab2];
		}
	},
}
</script>

<style>
</style>
