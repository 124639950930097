import VueRouter from 'vue-router';
import Vue from 'vue';
Vue.use(VueRouter);

import Inicio from '../vistas/Inicio';
import Paralax from '../vistas/Paralax';
import Login from '../vistas/Login';
import Transparencia from '../vistas/Transparencia';
import Obras from '../vistas/Obras';
import Gaceta from '../vistas/Gaceta';
import ContraloriaSocial from '../vistas/ContraloriaSocial';
import Conac from '../vistas/Conac';
import Administracion from '../vistas/Administracion';
import VerObra from '../vistas/VerObra';

const routes = [
	{path: "/", component: Paralax},
	{path: "/login", component: Login},
  {path: "/Transparencia", component: Transparencia},
  {path: "/Obras", component: Obras},
  {path: "/Gaceta", component: Gaceta},
  {path: "/Contraloria_Social", component: ContraloriaSocial},
  {path: "/Conac", component: Conac},
  {path: "/administracion", component: Administracion},
  {path: "/obras/ver/:id", component: VerObra},
]

const router = new VueRouter({ routes });

export default router;
