import Model from "./Model.js";
import Api from '../utils/Api';

export default class User extends Model {
	
	constructor() {
		super("users");
	}
	
	async login(data) {
		let response = await Api.post("/login", data);
		return response;
	}
	
	async logout() {
		let response = await Api.post("/logout");
		return response;
	}
}
