<template>
	<v-app style=""> 
		<NavBar />
		<v-main  class="principal">
			<v-row>
				<v-col></v-col>
				<v-col>
					<v-carousel style="width:80vw;" height="auto" > 
						<v-carousel-item v-for="(item,i) in items" :key="i" :src="item.src" reverse-transition="fade-transition" 
						transition="fade-transition"></v-carousel-item>
					</v-carousel>
				</v-col>
				<v-col></v-col>
			</v-row>
			<v-row style="margin:0; padding:10px 30px 0 10px;">
				<v-col class="columna" cols=9>
					<div style="margin:50px 0 0 0; padding:20px;">
						<p class="titulo">Noticias</p>
						<v-divider></v-divider>
						<v-slide-group v-model="slide" class="pa-4" active-class="success" show-arrows >
							<v-slide-item   v-slot="{ active, toggle }" v-for="(n, index) in noticias" :key="index">
								<v-hover v-slot="{ hover }" close-delay="200" >
									<v-card tile  :elevation="hover ? 16 : 2" style="margin:15px;padding:0;" width="20vw"  height="50vh">
										<v-img max-width="300" max-height="150" :src="n.portada" ></v-img>
										<v-card-title>{{n.titulo}}</v-card-title>
										<v-card-text>{{n.texto}}</v-card-text>
									</v-card>
								</v-hover>
							</v-slide-item>
						</v-slide-group>
					</div>
					<div style="margin:50px 0 0 0; padding:20px;">
						<p class="titulo">Convocatorias y Eventos</p>
						<v-divider></v-divider>
						<v-card tile  style="margin:15px;padding:0;" width="65vw"  height="38vh" v-for="(n, index) in eventos" :key="index">
							<v-row class="no-mp">
								<v-col style="margin:0;padding:15px;" cols="6">
									<v-card-title><h3>{{n.titulo}}</h3></v-card-title>
									<v-card-text>{{n.texto}}</v-card-text>
								</v-col>
								<v-col class="no-mp" cols="6">
									<v-img max-width="500" max-height="238" :src="n.portada"></v-img>
								</v-col>
							</v-row>
						</v-card>
					</div>
				</v-col>
				<v-col style="margin:0;padding:50px 0 0 0;" cols=3>
					<iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fweb.facebook.com%2Fticsoaxaca&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="340" height="500" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
				</v-col>
			</v-row>
		</v-main>
		<Footer />
	</v-app>
</template>

<script>
import NavBar from '../generales/NavBar';
import Footer from '../generales/Footer';
export default {
  components:{NavBar, Footer},
  data () { return { 
		slide:null,
		noticias: [
			{
				titulo:"Noticia ",
				texto:"Lorem Ipsum. Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...",
				id:1,
				portada:"http://sanlorenzotexmelucan.gob.mx/datos/texmelucan/obras/2021_AMPLIACION_DEL_SISTEMA_DE_AGUA_POTABLE_EN_LA_LOCALIDAD_DE_RIO_DE_TALEA/IMG-20210630-WA0027.jpg",
			}, 
			{
				titulo:"Noticia ",
				texto:"Lorem Ipsum. Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...",
				id:2,
				portada:"http://sanlorenzotexmelucan.gob.mx/datos/texmelucan/obras/2021_AMPLIACION_DEL_SISTEMA_DE_AGUA_POTABLE_EN_LA_LOCALIDAD_DE_RIO_DE_TALEA/IMG-20210630-WA0031.jpg",
			},
			{
				titulo:"Noticia ",
				texto:"Lorem Ipsum. Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...",
				id:3,
				portada:"http://sanlorenzotexmelucan.gob.mx/datos/texmelucan/obras/2021_AMPLIACION_DEL_SISTEMA_DE_AGUA_POTABLE_EN_LA_LOCALIDAD_DE_RIO_DE_TALEA/IMG-20210706-WA0035.jpg",
			},
			
		],
		eventos: [
			{
				titulo:"Evento ",
				texto:"Lorem Ipsum. Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...",
				id:1,
				portada:"https://cdn.vuetifyjs.com/images/carousel/planet.jpg",
			}, 
			{
				titulo:"Convocatoria ",
				texto:"Lorem Ipsum. Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...",
				id:2,
				portada:"http://sanlorenzotexmelucan.gob.mx/datos/texmelucan/obras/2021_AMPLIACION_DEL_SISTEMA_DE_AGUA_POTABLE_EN_LA_LOCALIDAD_DE_RIO_DE_TALEA/IMG-20210630-WA0031.jpg",
			},
			
		],
		items: [
			{
				src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',
			},
			{
				src: 'http://sanlorenzotexmelucan.gob.mx/datos/texmelucan/archivos/slider_2019-01-19January14%3A13%3A59/cabecera_slider_paginas.png',
			},
			{
				src: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',
			},
		],
	}},
	mounted(){},
	methods: {},
	computed: {},
	watch: {},
}
</script>

<style>
</style>
