<template>
	<v-app>
		<v-container fill-height>
			<v-row align="center" justify="center">
				<v-col cols="4" style="padding:20px;"></v-col>
				<v-col cols="4" style="padding:20px;">
					<v-card style="padding:5px 10px 5px 10px">
						<v-form
							ref="form"
							v-model="valid"
							lazy-validation >
						<v-text-field :rules="[v => !!v || 'Correo requerido']" v-model="email" label="Correo electronico" outlined></v-text-field>
						<v-text-field :rules="[v => !!v || 'Contraseña requerida']" required v-model="password" type="password" label="Contraseña" outlined></v-text-field>
						<v-btn color="success" block elevation="0" v-on:click="login">Iniciar session</v-btn>
						</v-form>
					</v-card>
				</v-col>
				<v-col cols="4" style="padding:20px;">
					<v-snackbar v-model="snackbar" :vertical="true" :timeout="2000" >
						Las credenciales no coinciden 
 						<template v-slot:action="{ attrs }">
							<v-btn color="indigo" text v-bind="attrs"
							@click="snackbar = false" >
							Cerrar
							</v-btn>
						</template>
					</v-snackbar>
				</v-col>
			</v-row>
		</v-container>
		
		<v-dialog v-model="splash" hide-overlay persistent width="300" > 
			<v-card color="primary" dark >
				<v-card-text>
					{{splashText}}
					<v-progress-linear indeterminate color="white" class="mb-0" ></v-progress-linear>
        		</v-card-text>
      		</v-card>
    </v-dialog>
	</v-app>
</template>

<script>
import Helper from '../utils/Helper'
import User from '../models/User'
export default {
	name:'Login',
  components:{},
  data () { return { 
		email: null,
		password: null,
		valid:null,
		snackbar:false,
	}},
	mounted() {},
	methods: {
		login() {
			//console.log(Helper.hash(this.password));
			if(this.$refs.form.validate()){
			this.$store.commit('setsplash',{activar:true, texto:'Iniciando sesion'});
			let u = new User();
			
			u.login({email:this.email,password:this.password}).then((response) => {
				//console.log(response.data);
				this.$store.commit('setsplash',{activar:false, texto:''});
				if(response.data.error) {
					//alert(response.data.message);
					this.snackbar =true;
				} else {
					localStorage.setItem('token',response.data.token);
					localStorage.setItem('user_id',response.data.user.id);
					this.$store.commit('setuser',response.data.user);
					this.$router.push('/administracion');
				}
			});
			}
			//this.$store.commit('setsplash',{activar:false, texto:''});
			//this.$router.push('/administracion');
		},
	},
	computed: { 
		logo() {
			//return require('../imagenes/logo-buo.png');
		},
		splash() {
			return this.$store.state.splash;
		},
		splashText() {
			return this.$store.state.splashText;
		},
	},
	watch: {},
}
</script>

<style>
</style>
