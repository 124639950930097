<template>
	<v-card style=" padding:15px" elevation=0>
		<v-expansion-panels>
			<v-expansion-panel v-for="normas,conjunto in normasPorConjunto" :key="conjunto">
				<v-expansion-panel-header>{{conjunto}}</v-expansion-panel-header>
				<v-expansion-panel-content>
          <v-data-table
          :headers="[{ text: 'Nombre', value: 'nombre' }, { text: '', value: 'actions', width: '60px' }]"
          :items="normas" item-key="nombre"  hide-default-footer disable-pagination elevation="0">
            <template v-slot:item.actions="{ item }">
              <v-btn color="primary" @click="verDocs(item)"><i class="fa fa-file"></i>&nbsp;Ver Archivos</v-btn>
            </template>
          </v-data-table>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
		<!-- -->
    <v-dialog width="500" v-model="docsModal" persistent>
			<v-card style="margin:0" v-if="normaSelect">
				<v-toolbar dark  elevation="0" class="cabecera-azul">
					<v-btn text v-on:click="cerrar"><i class="fa fa-times"></i></v-btn><v-spacer></v-spacer>{{ normaSelect.nombre }}
				</v-toolbar>
				<v-container>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Archivo
                  </th>
                  <th class="text-left">
                    Tipo
                  </th>
                  <th class="text-left">
                    link
                  </th>
                  <th class="text-left">
                    ver
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="a, index in normaSelect.archivos" :key="a.id + 'arc'">
                  <td>{{ a.nombre }}</td>
                  <td>{{ a.archivo.tipo }}</td>
                  <td><v-btn x-small outlined id="chido" v-on:click="copy('chido', a)"><i class="fa fa-link"></i></v-btn></td>
                  <td><v-btn x-small outlined v-on:click="ver(a)"><i class="fa fa-file"></i></v-btn></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
					<v-card-actions>
						<v-btn color="red" dark v-on:click="cerrar">Cancelar</v-btn>
					</v-card-actions>
				</v-container>
			</v-card>
		</v-dialog>
	</v-card>
</template>

<script>
import Norma from '../../models/Norma';
export default {
  components:{},
  props: {
		anho: {
			type:Number,
			default() {return 1;}
		},
	},
  data () { return { 
		normas: [],
    normaSelect: null,
    docsModal: false
	}},
	mounted() {
		this.cargarNormas();
	},
	methods: {
		copy(id, a) {
			Helper.copyClipboard(document.getElementById(id), a.archivo.url).then(() => {  alert("Link copiado"); })
		},
		ver(a) {
			console.log(a.archivo.tipo);
			let tipos = ['xlsx','xls','xlsm'];
			if(tipos.includes(a.archivo.tipo)) {
				window.open('https://view.officeapps.live.com/op/view.aspx?src='+a.archivo.url , '_blank');
			} else {
				window.open(a.archivo.url , '_blank');
			}
		},
		cargarNormas() {
			let f = new Norma();
			this.$store.commit('setsplash',{activar:true,texto:'Subiendo archivo...'});
			f.listarNormas(this.anho).then(response => {
				this.normas = response.data;
				this.$store.commit('setsplash',{activar:false,texto:''});
			});
		},
    verDocs (item) {
      this.normaSelect = item
      this.docsModal = true
    },
    cerrar () {
      this.normaSelect = null
      this.docsModal = false
    }
	},
	computed: {
		normasPorConjunto() {
			let conjuntos =  {
				'INFORMACION FINANCIERA':[],
				'INFORMACION DE LEY DE INGRESOS Y PRESUPUESTO DE EGRESOS':[],
				'CALENDARIO':[],
				'EJERCICIO DEL PRESUPUESTO':[],
				'EVALUACION Y RENDICIÓN DE CUENTAS':[],
				'LEY DE DISCIPLINA FINANCIERA':[],
			};
			this.normas.forEach((item) => {
				switch(item.conjunto_id) {
					case 1:conjuntos['INFORMACION FINANCIERA'].push(item); break;
					case 2:conjuntos['INFORMACION DE LEY DE INGRESOS Y PRESUPUESTO DE EGRESOS'].push(item); break;
					case 3:conjuntos['CALENDARIO'].push(item); break;
					case 4:conjuntos['EJERCICIO DEL PRESUPUESTO'].push(item); break;
					case 5:conjuntos['EVALUACION Y RENDICIÓN DE CUENTAS'].push(item); break;
					case 6:conjuntos['LEY DE DISCIPLINA FINANCIERA'].push(item); break;
				};
			});
			return conjuntos;
		},
	},
	watch: {
		anho() {
			this.cargarNormas();
		},
	},
}
</script>

<style>
</style>
