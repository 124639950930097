<template>
	<v-sheet
    id="contact"
    color="#333333"
    dark
    tag="section"
    tile
  >
    <v-container>
      <h4 class="display-2 font-weight-bold mb-3 text-uppercase text-center">Contacto</h4>
      <a :href="sitioAnt" target="_blank">Acceso a la plataforma Anterior</a>

      <v-responsive class="mx-auto mb-12" width="56">
        <v-divider class="mb-1"></v-divider>
        <v-divider></v-divider>
      </v-responsive>
      <v-row>
          <v-col class="text-center" cols=12 md="4">
            <iframe :src="urlMaps" width="400" height="350" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </v-col>
          <v-col class="text-center" cols=12 md="4">
            <iframe :src="urlFace" width="340" height="500" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
          </v-col>
          <v-col cols=12 md="4">
            <p>Direccion: {{ direccion }}</p>
            <p>Telefono: {{ telefono }}</p>
            <p>Email: {{ email }}</p>
            <p>Horario de Atencion: {{ horario }}</p>
          </v-col>
        </v-row>
      <v-theme-provider light>
        
      </v-theme-provider>
    </v-container>

    <div class="py-12"></div>
  </v-sheet>
</template>

<script>
export default {
  components:{},
  data () { return {  }},
	mounted(){},
	methods: {},
	computed: {
    urlMaps () {
      return !this.$store.state.config.Maps? '':this.$store.state.config.Maps
    },
    urlFace () {
      return !this.$store.state.config['Facebook View']? '':this.$store.state.config['Facebook View']
    },
    direccion () {
      return !this.$store.state.config.Direccion? '':this.$store.state.config.Direccion
    },
    telefono () {
      return !this.$store.state.config.Telefono? '':this.$store.state.config.Telefono
    },
    email () {
      return !this.$store.state.config.Email? '':this.$store.state.config.Email
    },
    horario () {
      return !this.$store.state.config.Horario? '':this.$store.state.config.Horario
    },
    sitioAnt() {
      return !this.$store.state.config['Sitio Anterior']? null: this.$store.state.config['Sitio Anterior']
    }
  },
	watch: {},
}
</script>

<style>
</style>
