<template>
	<v-app style=""> 
		<NavBar />
		<v-main class="principal" style="margin:20px;">
      <v-card-title>Contraloria Social</v-card-title>
			<v-row class="fila">
				<v-col class="columna" cols=9> Esquema de Contraloria Social </v-col>
				<v-col class="columna" cols=2></v-col>
				<v-col class="columna" cols=1><v-btn><i class="fa fa-download"></i></v-btn></v-col>
			</v-row>
			<v-row class="fila" style="background-color:#B1ABAB;">
				<v-col class="columna" cols=9> Guia Operativa </v-col>
				<v-col class="columna" cols=2></v-col>
				<v-col class="columna" cols=1><v-btn><i class="fa fa-download"></i></v-btn></v-col>
			</v-row>
			<v-row class="fila" >
				<v-col class="columna" cols=9> PETC </v-col>
				<v-col class="columna" cols=2></v-col>
				<v-col class="columna" cols=1><v-btn><i class="fa fa-download"></i></v-btn></v-col>
			</v-row>
			<v-row class="fila" style="background-color:#B1ABAB;">
				<v-col class="columna" cols=9> Triptico </v-col>
				<v-col class="columna" cols=2></v-col>
				<v-col class="columna" cols=1><v-btn><i class="fa fa-download"></i></v-btn></v-col>
			</v-row>
		</v-main>
		<Footer />
	</v-app>
</template>

<script>
import NavBar from '../generales/NavBar';
import Footer from '../generales/Footer';
export default {
  components:{NavBar, Footer},
  data () { return { }},
	mounted(){},
	methods: {},
	computed: {},
	watch: {},
}
</script>

<style>
</style>
