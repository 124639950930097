<template>
	<div>
		<router-view v-if="listo"></router-view>
		<!-- splash-->
		 <v-dialog v-model="splash"  persistent width="300" >
      <v-card color="primary" dark >
        <v-card-text>
          {{textSplash}}
          <v-progress-linear indeterminate color="white" class="mb-0" ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
		<!-- ./splash-->
	</div>
</template>

<script>
import Aplicacion from './utils/App'
import Config from './models/Config'
export default {
  components:{},
  data () { return {
		listo:false,
	}},
	mounted() {
		Aplicacion.start().then(() => {
      const c = new Config()
      c.listar().then((response) => {
        this.$store.commit('setconfig', response.data)
        console.log(this.$store.state.config)
        this.listo = true
      });
		});
	},
	methods: {},
	computed: {
		splash() {
			return this.$store.state.splash;
		},
		textSplash() {
			return this.$store.state.splashText;
		},
	},
	watch: {},
}
</script>

<style>
</style>
