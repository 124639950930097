import Model from "./Model.js";

export default class Norma extends Model {
	
	constructor() {
		super("normas");
	}
	
	async listarNormas(anho) {
		return await this.post("listar_normas",{anho});
	}
	
	async anhosNorma() {
		return await this.post("anhos_normas");
	}
	
	async agregarArchivo(data) {
		return await this.post("agregar_archivo",data);
	}
	
	async borrarArchivo(norma_archivo_id) {
		return await this.post("borrar_archivo",{norma_archivo_id});
	}
	
	async crearBloque(anho) {
		return await this.post("crear_bloque", {anho});
	}
	
}
