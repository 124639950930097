<template>
	<v-container>
		<v-row v-if="acciones">
			<v-col><v-btn v-on:click="agregar">Agregar Imagen</v-btn></v-col>
		</v-row>
		<v-row>
			<v-col cols=3 v-for="i,index in imagenes" :key="index">
				<v-card>
					<v-card-text v-on:click="ver(i.archivo.url)" class="text-center">
						<v-img max-height="150" max-width="400" :src="i.archivo.url" ></v-img>
					</v-card-text>
					<v-card-actions v-if="acciones">
						<v-card elevation=0 class="text-center" style="background-color: transparent!important;"
						v-on:click="borrar(i,index)">
							<div><i class="fa fa-trash" ></i></div>
							<div class="text-tini">Borrar</div>
						</v-card>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
		<v-dialog v-model="modalImage">
			<v-card>
				<v-toolbar dark  elevation="0" class="cabecera-azul">
					<v-btn text v-on:click="modalImage = false"><i class="fa fa-times"></i></v-btn> <v-spacer></v-spacer>Imagen
				</v-toolbar>
				<div class="text-center">
					<v-img :src="imgUrlSelect"></v-img>
				</div>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
export default {
  components:{},
  props: {
		imagenes: {
			type:Array,
			default() { return []; }
		},
		acciones: {
			type:Boolean,
			default() { return true; }
		},
	},
  data () { return { 
		imgUrlSelect: null,
		modalImage:false,
	}},
	mounted() { },
	methods: {
		ver(url) {
			this.imgUrlSelect= url;
			this.modalImage = true;
		},
		agregar() {
			this.$emit("agregar");
		},
		borrar(i,index) {
			this.$emit("borrar",i,index);
		}
	},
	computed: {},
	watch: { },
}
</script>

<style>
</style>
